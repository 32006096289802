import { getProductHours } from 'utils/product'
import _ from 'lodash'
import { PhaseItemGroupType, PhaseRowItemType, Product } from '@tools/wr-catalog-base'

export const useCartCalculations = (
  cart: Product[] = [],
  selectedAddons: PhaseRowItemType[] = [],
  itemGroups: PhaseItemGroupType[] | null = null,
) => {
  let hourFieldTotals = {} as HoursCalculatedType

  cart.forEach((cartItem) => {
    const productHours = getProductHours(cartItem, cart, selectedAddons, itemGroups)

    hourFieldTotals = _.mergeWith(hourFieldTotals, productHours, (objValue, srcValue) =>
      _.isNumber(objValue) ? objValue + srcValue : srcValue,
    )
  })

  return {
    totalWeeks: +hourFieldTotals.totalWeeks?.toFixed(2),
    totalHours: +hourFieldTotals.totalHours?.toFixed(2),
  } as HoursCalculatedType
}
