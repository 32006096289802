import React, { useEffect } from 'react'
import {
  BackButton,
  Col,
  ProductDetail,
  Redirect,
  Row,
  SpacedCol,
  context,
  updateContextState,
  Product,
  PhaseRowItemType,
  RouteComponentProps,
} from '@tools/wr-catalog-base'
import Cart from '@components/Cart'

import { Wrapper } from './style'
import { getAddonsFromSelections } from './utils'

interface AddonsViewProps extends RouteComponentProps {
  addOnItems: PhaseRowItemType[]
  selectedAddons: PhaseRowItemType[]
  cart: Product[]
}

function AddonsView(props: AddonsViewProps) {
  const { addOnItems, selectedAddons, cart } = props

  useEffect(() => {
    updateContextState((state) => {
      state.selectedAddons = getAddonsFromSelections(cart, addOnItems, selectedAddons)
    })
  }, [])

  if (!cart.length) {
    return <Redirect to={context.getRoutePath('index')} />
  }

  return (
    <Wrapper className="container">
      <Row>
        <SpacedCol xs={12}>
          <BackButton />
        </SpacedCol>
      </Row>
      <Row>
        <Col xs={8}>
          {selectedAddons?.map((addon, i) => {
            const ColComponent = i === 0 ? Col : SpacedCol
            return (
              <ColComponent key={addon.addonId} xs={12}>
                <ProductDetail
                  initialValues={addon.values}
                  productId={addon.id}
                  productFetch={{ value: addon as unknown as Product }}
                  onChange={(values) => {
                    updateContextState(() => {
                      addon.values = values
                    })
                  }}
                  enableCart={false}
                  showDescription={false}
                  validateCart
                  enableValidation
                />
              </ColComponent>
            )
          })}
        </Col>
        <SpacedCol xs={4}>
          <Cart nextUrl={context.getRoutePath('phases')} />
        </SpacedCol>
      </Row>
    </Wrapper>
  )
}

export default AddonsView
