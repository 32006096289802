import { context } from '@tools/wr-catalog-base'

export const fetchOrCreate = async (
  sfObjectName: string,
  where: Record<string, string | boolean | number>,
  initialValues: Record<string, string | boolean | number>,
) => {
  const sfConn = context.constants.getSfConn()
  const sfRecord = await sfConn.sobject(sfObjectName).findOne(where)

  if (sfRecord) return sfRecord

  const res = await sfConn.sobject(sfObjectName).create(initialValues)

  if (!res.success) {
    return res
  }

  const { id } = res

  const sfRecordNew = await sfConn.sobject(sfObjectName).findOne({ Id: id })
  return sfRecordNew
}
