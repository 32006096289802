import React from 'react'
import { FaEllipsisV } from 'react-icons/fa'
import {
  Dropdown,
  Button,
  Link,
  i18n,
  buildConfirmationModal,
  proposalRequests,
  withContext,
  updateContextState,
  WrContextType,
  Proposal,
  request,
  isAnalyticsEnabled,
  context,
} from '@tools/wr-catalog-base'
import { loadProposalIntoState } from 'utils/proposals/loadProposalIntoState'
import mixpanel from 'mixpanel-browser'

function ActionsDropdown(props: { context: WrContextType; row: Proposal }) {
  const handleDelete = (id: string) => {
    props.context.actions.openModal(
      buildConfirmationModal({
        body: <div data-testid="proposals-delete-modal">{i18n('proposals.removeProposalsConfirmation')}</div>,
        onConfirm: () => {
          request(proposalRequests.deleteProposal(id).deleteFetch as any)
        },
      }),
    )
  }

  const handleEdit = (proposal: Proposal) => {
    updateContextState((state) => {
      const [catalogId] = props.context.constants.client.catalogIds
      const items = props.context.state?.catalogs?.find((c) => c.id === catalogId)?.contents?.items

      loadProposalIntoState(state, proposal, items)
    })

    props.context.history.push(props.context.getRoutePath('index'))
  }

  const handleAction = (status: string) => {
    const el = document.querySelector<HTMLElement>(`[data-rowId="${props.row.id}"]`)
    const proposalStatusChangedEvent = context.constants.analytics.proposalStatusChanged

    if (el) {
      el.dataset.status = status
      el.textContent = status
    }

    if (status === 'Approved') {
      isAnalyticsEnabled() &&
        mixpanel.track(proposalStatusChangedEvent, { status: 'Approved', proposalId: props.row.id })
      return localStorage.setItem(props.row.id, 'Approved')
    }

    isAnalyticsEnabled() && mixpanel.track(proposalStatusChangedEvent, { status: 'Rejected', proposalId: props.row.id })
    localStorage.setItem(props.row.id, 'Rejected')
  }

  return (
    <Dropdown>
      {({ DropdownTrigger, DropdownList }) => (
        <>
          <DropdownTrigger data-testid="proposals-actions-open" style={{ textAlign: 'center' }}>
            <Link>
              <FaEllipsisV />
            </Link>
          </DropdownTrigger>
          <DropdownList>
            <ul>
              <li>
                <Button
                  data-testid="proposals-actions-approve"
                  type="button"
                  onClick={() => handleAction('Approved')}
                  fullWidth
                >
                  Approve
                </Button>
              </li>
            </ul>
            <ul>
              <li>
                <Button
                  data-testid="proposals-actions-reject"
                  type="button"
                  onClick={() => handleAction('Rejected')}
                  fullWidth
                >
                  Reject
                </Button>
              </li>
            </ul>
            <ul>
              <li>
                <Button
                  data-testid="proposals-actions-delete"
                  onClick={() => handleDelete(props.row.id)}
                  primary={false}
                  fullWidth
                >
                  {i18n('proposals.actions.delete')}
                </Button>
              </li>
            </ul>
            <ul>
              <li>
                <Button
                  data-testid="proposals-actions-edit"
                  type="button"
                  onClick={() => handleEdit(props.row)}
                  fullWidth
                >
                  Edit
                </Button>
              </li>
            </ul>
          </DropdownList>
        </>
      )}
    </Dropdown>
  )
}

export default withContext(ActionsDropdown) as any as (props: { row: Proposal }) => JSX.Element
