import { context as globalContext, phasesModule, Product } from '@tools/wr-catalog-base'
import getProductPrice from './getProductPrice'

export const getCartTotalPrice = (cart: Product[], context = globalContext) => {
  let result = 0
  cart.forEach((product) => {
    result += getProductPrice(product, true)
  })

  if (context.state.phasesConfig?.itemGroups?.length) {
    context.state.phasesConfig.itemGroups[0].itemRows.forEach((phasesRow) => {
      let itemRowCost = phasesModule.utils.calculateItemRowCost(phasesRow, { rateOverrideKey: null })
      result += itemRowCost.fullCost / 100
    })
  }

  return result
}
