import React from 'react'
import {
  context,
  getIn,
  getProductRow,
  Product,
  ProductRawValue,
  ProductRowOptionItem,
  ProductRowType,
  RowVariants,
  safeJsonParse,
} from '@tools/wr-catalog-base'

/**
 * @refactor to remove
 */
export const valueToString = (
  val: ProductRawValue | ProductRawValue[] | string,
  notes: ProductRowOptionItem[] = [],
) => {
  const v = safeJsonParse(val)
  const note =
    getIn(
      notes.find((n) => n.id === v.id),
      'noteValue',
    ) || ''
  return `${v.label || v.value || v}${note ? ` (${note})` : ''}`
}

/**
 * @refactor to remove
 */
export const rowValueToString = (rows: ProductRowType[], sep = ' - ') =>
  rows
    .map((row) => {
      const value = Array.isArray(row.value)
        ? row.value.map((v) => valueToString(v, row.notes)).join(', ')
        : valueToString(row.rawValue || '', row.notes)
      return value && `${row.label}${sep}${value}`
    })
    .filter(Boolean)

export const roleHourFieldToString = (field: string, value: string | number) => {
  const role = field?.split('total')[1]?.split('Hours')?.[0]

  if (!role) return ''

  return `Total ${role} Hours: ${value}`
}

/**
 * @refactor to remove
 */
export const renderRoleHoursHtml = (hoursObject: Partial<HoursCalculatedType>) => {
  return Object.entries(hoursObject)
    .filter(([entryName]) => entryName !== 'totalHours' && entryName.includes('Hours'))
    .map(([field, value]) => {
      return <p className="cart-muted">{roleHourFieldToString(field, value)}</p>
    })
}

export const getAllowedTemplates = (cart: Product[]): SelectedTemplateType[] => {
  const allowedTemplates = cart?.reduce((arr: string[], product) => {
    const templateControl: any = getProductRow(product, RowVariants.RowType, 'component:document-template')?.control

    const templates = templateControl?.templateId

    if (!templates) return arr

    return [...new Set([...arr, ...templates.split(',')])]
  }, [])

  const result = allowedTemplates
    ?.map((templateId) => {
      const template = context.state.templates.find((template) => template.id === templateId)

      if (!template) return null

      return {
        label: template.name,
        value: template.id,
      }
    })
    .filter(Boolean)

  return (result || []) as SelectedTemplateType[]
}
