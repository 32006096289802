import { context, request } from '@tools/wr-catalog-base'

export const fetchSOW = async (templateId = '') => {
  try {
    return await request({
      url: `${context.constants.appHost}/api/v1/templates/search?id=${templateId}`,
      method: 'GET',
    })
  } catch (e) {
    console.log('error', e)
  }

  return null
}
