import React, { MutableRefObject, useRef } from 'react'
import propTypes from 'prop-types'
import {
  withContext,
  context,
  updateContextState,
  GanttView,
  styled,
  RouteComponentProps,
  WrContextType,
  PhasesConfigType,
} from '@tools/wr-catalog-base'
import SvgGantt from './components/SvgGantt'
import { svgToImage } from './utils'
import { elementToSVG } from 'dom-to-svg'

const Container = styled.div`
  margin-top: 30px;
`

/**
 * @typedef {Object} PhasesBreakdown
 * @property {Array<Array<number>>} data
 * @property {Array} phases
 * @property {Array} roles
 */

/**
 * @typedef {Object} ItemRow
 * @property {PhasesBreakdown} phasesBreakdown
 */

/**
 * @typedef {Object} ItemGroup
 * @property {Array<ItemRow>} itemRows
 */

/**
 *
 * @param {Object} props
 * @param {Object} props.phaseConfig
 * @param {Array<ItemGroup>} props.phaseConfig.itemGroups
 * @returns
 */

interface GanttViewProps extends RouteComponentProps {
  context: WrContextType
  selectedRegion: string
  phasesConfig: PhasesConfigType
}

function GanttPage(props: GanttViewProps) {
  const { phasesConfig: { itemGroups = [] } = {}, selectedRegion } = props

  const onSubmit = async () => {
    try {
      if (!svgGanttChartRef.current) return

      const svg = elementToSVG(svgGanttChartRef.current)
      const ganttChartData = await svgToImage(svg.getElementsByTagName('svg')?.[0])

      updateContextState((state) => {
        state.ganttChartData = ganttChartData
      })
    } catch (err) {
      console.error('There was an error generating the chart image')
      console.error(err)
    }
    // @refactor
    // clean cartProducts first
    context.state.cartProducts = {
      'managed-services': [],
      'implementation-services': [],
      'technical-services': [],
    }
    context.history.push(context.getRoutePath('document-edit'))
  }

  const svgGanttChartRef = useRef<HTMLDivElement | undefined>(undefined)
  const svgAdditionalComponentRef = useRef<SVGSVGElement>(null)

  const additionalComponents = (
    <SvgGantt
      itemGroups={itemGroups}
      svgRef={svgAdditionalComponentRef as unknown as MutableRefObject<SVGSVGElement>}
    />
  )

  return (
    <Container data-testid="gantt-container">
      <GanttView
        gantRef={svgGanttChartRef as unknown as MutableRefObject<HTMLDivElement>}
        onSubmit={onSubmit}
        isSubmitDisabled={typeof selectedRegion === 'undefined'}
        additionalComponents={additionalComponents}
      />
    </Container>
  )
}

GanttView.propTypes = {
  cart: propTypes.array,
  phasesConfig: propTypes.object,
}

export default withContext(GanttPage, {
  mapProps: (context) => ({
    cart: context.state.cart,
    phasesConfig: context.state.phasesConfig,
    selectedRegion: context.state.selectedRegion,
  }),
})
