import React from 'react'
import { styled } from '@tools/wr-catalog-base'
import { FaShoppingCart } from 'react-icons/fa'

export const CartContainer = styled.div`
  position: sticky;
  top: 70px;
  text-align: initial;
  padding: 30px 40px;
  width: 100%;
  max-width: 474px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 10px 15px 30px -5px rgba(0, 0, 0, 0.07);

  .cart-active {
    color: #5cc1a8 !important;
  }

  hr {
    color: transparent;
    border: 1px solid #ddd;
  }
`

const CartBaseStyles = styled.div`
  .cart-muted {
    color: #858585;
    font-family: 'Chivo', sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: uppercase;
    margin: 2.5px 0;
  }
`

export const CartHeading = styled(CartBaseStyles)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  .cart-heading {
    color: #858585;
    font-family: 'Chivo', sans-serif;

    font-size: 21px !important;
    margin-bottom: 10px;
  }

  & > *:last-child {
    text-align: right;
  }
`

export const CartHours = styled(CartBaseStyles)`
  color: #858585;
  font-family: 'Chivo', sans-serif;

  font-size: 21px !important;
  margin-bottom: 14px;
`

export const CartBody = styled.div`
  min-height: 244px;
  overflow-y: auto;
  max-height: 60vh;
`

export const CartFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;

  button {
    text-transform: initial;
  }

  &:first-child {
    margin-right: 12px;
  }

  &[data-disabled='true'] .dropdown-trigger {
    pointer-events: none;
  }

  &[data-disabled='true'] a,
  &[data-disabled='true'] button {
    background: #e3e3e3;
    pointer-events: none;
    color: #fff;
  }
`

export const CartItemHours = styled.div`
  margin-bottom: 10px;
`

export const CartItemInfoAndActions = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > div:first-child {
    flex: 0 1 10%;
  }

  & > div:nth-child(2) {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }

  & > div:nth-child(3) {
    flex: 1 1 20%;
    text-align: right;
  }
`

export const CartItemWrapper = styled.div`
  .item-name {
    font-weight: bold;
    margin-bottom: 13px;
  }

  .item-description {
    color: #b0b0b0;
    margin-bottom: 13px;
  }

  .item-delete-icon {
    fill: #5cc1a8;
    cursor: pointer;
  }

  a {
    text-decoration: underline;
  }
`

const CartPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #d3d3d3;
  padding: 70px 0;

  .placeholder-icon {
    display: block;
    fill: #d3d3d3;
    font-size: 48px;
    margin-bottom: 20px;
  }
`

export const DropDownInputWrapper = styled.div`
  padding-bottom: 30px;
`

export const AddonsListWrapper = styled.div`
  padding-bottom: 10px;
`

export const CartPlaceholder = () => (
  <CartPlaceholderWrapper>
    <FaShoppingCart className="placeholder-icon" />
    <span className="placeholder-muted">
      Choose through the services and products. Configure to your needs and add them to your cart.
    </span>
  </CartPlaceholderWrapper>
)
