import { withContext } from '@tools/wr-catalog-base'
import { getSelectedTemplate } from 'utils/proposals/getSelectedTemplate'

import Cart from './Cart'

export default withContext(Cart, {
  mapProps: (context) => {
    return {
      cart: context.state.cart,
      openModal: context.actions.openModal,
      removeProductFromCart: context.actions.removeProductFromCart,
      getRoutePath: context.getRoutePath,
      theme: context.theme,
      templates: context.state.templates,
      templateSelected: getSelectedTemplate(context),
      defaultTemplateId: context.state.templates.find((t) => t.isDefault)?.id,
      addOnItems: context.state.addOnItems,
      selectedAddons: context.state.selectedAddons,
      selectedRegion: context.state.selectedRegion,
    }
  },
})
