import React from 'react'
import { Link, StickyHeader, styled, withContext, Row, Col, WrContextType } from '@tools/wr-catalog-base'
import RegionDropdown from '@components/RegionDropdown'

const StickyHeaderStyled = styled(StickyHeader)`
  @media (min-width: 768px) {
    justify-content: flex-end;
    gap: 20px;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 0 10px;
  }

  & svg {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};

    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.midGreen};
    }
  }
`

const EndColumn = styled(Col)`
  justify-content: flex-end;
  display: flex;
  align-items: center;
`

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    margin: 30px 0 15px;
  }
`

const RowStyled = styled(Row).attrs({
  around: 'xs',
  between: 'sm',
})``

const DropdownWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const Header = ({ context }: { context: WrContextType }) => {
  return (
    <StickyHeaderStyled>
      <div className="container" data-testid="catalog-header">
        <RowStyled>
          <Row middle="xs">
            <Col>
              <Link to="/">Powered by WorkRails</Link>
            </Col>
          </Row>

          <EndColumn md>
            <Row around="xs">
              <DropdownWrapper>
                <RegionDropdown />
              </DropdownWrapper>
              {(context.state.isLoggedIn || process.env.REACT_APP_SHOW_MY_SOW) && (
                <CTAWrapper>
                  <Link to={context.getRoutePath('proposals')} style={{ marginRight: '20px' }}>
                    {/* View proposals */}
                    My SOWs
                  </Link>
                  {context.constants.requireAuth && (
                    <Link to="/" onClick={context.actions.logout}>
                      Logout
                    </Link>
                  )}
                </CTAWrapper>
              )}
            </Row>
          </EndColumn>
        </RowStyled>
      </div>
    </StickyHeaderStyled>
  )
}

export default withContext(Header)
