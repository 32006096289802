import { yup } from '@tools/wr-catalog-base'

export const DECISION_TREE_INITIAL_VALUES = {
  company: '',
  name: '',
  email: '',
}

export const DECISION_TREE_VALUES_TYPES = {
  company: 'text',
  name: 'text',
  email: 'email',
}

export const DECISION_TREE_VALIDATION_SCHEMA = yup.object({
  company: yup.string().required('Company is a required field'),
  name: yup.string().required('Name is a required field'),
  email: yup.string().email().required('Email is a required field'),
})
