import { PhaseItemRowType, PhasesGetDiscountType } from '@tools/wr-catalog-base'

const getAddonDiscount: PhasesGetDiscountType = (itemRow, config) => {
  const { fullCost = 0 } = config || {}

  const discountAmount = (itemRow as PhaseItemRowType)?.item?.discountAmount
  return discountAmount || (fullCost * +((itemRow as PhaseItemRowType)?.item?.discountPercent || 0)) / 100
}

export default getAddonDiscount
