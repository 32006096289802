import React, { useEffect, useCallback, useState, useRef } from 'react'
import {
  Row,
  Col,
  Title,
  SplitView,
  ProductList,
  SpacedCol,
  DecisionTree,
  context,
  debounce,
  withContext,
  Product,
  FlowQuestionType,
  RouteComponentProps,
} from '@tools/wr-catalog-base'
import { DECISION_TREE_INITIAL_VALUES, DECISION_TREE_VALUES_TYPES, DECISION_TREE_VALIDATION_SCHEMA } from './consts'
import { DecisionTreeContainer, StylesWrapper, StyledLink } from './style'
import Cart from '@components/Cart'
import Hero from '@components/Hero'
import { WrContextType } from '@tools/wr-catalog-base'

interface ListRouteActions {
  updateProductsToDisplay: (products: string | string[] | null | undefined) => void
  updateTab: (currentTab: string) => void
}
interface ListRouteProps extends ListRouteActions, RouteComponentProps {
  catalogDescription: string
  productsToDisplay: Product[]
  currentTab: string
}

const List = ({
  catalogDescription,
  productsToDisplay,
  updateProductsToDisplay,
  updateTab,
  currentTab,
}: ListRouteProps) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const toggleView = useRef<((tab: string) => void) | null>(null)

  const setToggleView = useCallback((v) => {
    toggleView.current = v
  }, [])

  const decisionTreeReset = useRef<(() => void) | null>(null)

  const setDecisionTreeReset = useCallback((v) => {
    decisionTreeReset.current = v
  }, [])

  useEffect(() => {
    const handler = debounce(() => setScreenHeight(window.innerHeight), 200)
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  useEffect(() => {
    context.actions.logIndexTransaction()
  }, [])

  const renderProductBody = useCallback(
    ({ description, headerRows }) => {
      const truncateLimit = Math.ceil(screenHeight / 3)
      const truncate = (str: string) =>
        str?.length < truncateLimit ? str : `${str.toString().slice(0, truncateLimit - 3)}...`
      return (
        <>
          {truncate(description)}
          {headerRows}
        </>
      )
    },
    [screenHeight],
  )

  const handleNextQuestion = useCallback((q: FlowQuestionType) => {
    if (q.final && q.type === 'products') {
      updateProductsToDisplay(q.products && q.products.length ? q.products.map(({ id }) => id) : undefined)
      // Note: the line below has been removed because it makes it impossible for me to track successful decision tree paths.
      // After thorough testing, it doesn't seem to have an effect on the functionality or the performance of the catalog.
      // decisionTreeReset.current?.()
    }

    if (q.final && q.type === 'product') {
      updateProductsToDisplay(q.productId && [q.productId])
    }

    if (q.final && q.type === 'flow' && q.answer && q.answer.childValue) {
      context.history.push(context.getRoutePath('decisionTree', { flowId: q.answer.childValue }))
    }
  }, [])

  const handleViewChange = (view: string) => updateTab(view)

  useEffect(() => {
    if (currentTab === 'secondary') {
      toggleView.current?.('secondary')
    }
  }, [currentTab])

  const backToDT = () => {
    updateProductsToDisplay(null)
  }

  return (
    <>
      <Hero catalogTitle={catalogDescription} />
      <SplitView
        mainLabel="Services Catalog"
        secondaryLabel="Find the Right Services"
        betweenLabel="OR"
        getToggleView={setToggleView}
        handleViewChange={handleViewChange}
      >
        {({ MainView, SecondaryView }) => (
          <>
            <MainView>
              <StylesWrapper>
                <Row>
                  <Col xs={12} sm={8} data-testid="product-list-col">
                    <ProductList
                      displayPriceOnList
                      hidePriceAndDurationIfZero={true}
                      showAddedToOrderIcon
                      splitOnCategories={false}
                      showTitle={false}
                      showAddedToOrderBadge={true}
                      showFilters={false}
                      toolbar={true}
                      showBundles={true}
                      searchableKeys={['name', 'description']}
                      renderProductBody={renderProductBody}
                      toolbarType="categoriesDropdown"
                    />
                  </Col>
                  <SpacedCol xs={12} sm={4} data-testid="product-list-cart-col">
                    <Cart />
                  </SpacedCol>
                </Row>
              </StylesWrapper>
            </MainView>

            <SecondaryView>
              {productsToDisplay && (
                <Row center="xs">
                  <Col xs={12}>
                    <StyledLink onClick={backToDT} data-testid="list-go-to-questions">
                      Go back to the questions
                    </StyledLink>
                  </Col>
                </Row>
              )}
              <Row center="xs">
                <Col xs={12} sm={8}>
                  {!productsToDisplay && (
                    <DecisionTreeContainer data-testid="product-list-d3-container">
                      <DecisionTree
                        initialValues={DECISION_TREE_INITIAL_VALUES}
                        valuesTypes={DECISION_TREE_VALUES_TYPES}
                        validationSchema={DECISION_TREE_VALIDATION_SCHEMA}
                        onNextQuestion={handleNextQuestion}
                        getDecisionTreeReset={setDecisionTreeReset}
                        startOverOnMessageEnd
                        noBuildQuote
                        noSummary
                      />
                    </DecisionTreeContainer>
                  )}

                  {productsToDisplay && (
                    <div data-testid="product-list-custom-container">
                      <ProductList
                        displayPriceOnList
                        hidePriceAndDurationIfZero={true}
                        showAddedToOrderIcon
                        splitOnCategories={false}
                        showTitle={false}
                        showAddedToOrderBadge={true}
                        showFilters={false}
                        showBundles={true}
                        toolbar={false}
                        productsToDisplay={productsToDisplay}
                        searchableKeys={['name', 'description']}
                        renderProductBody={renderProductBody}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={4}>
                  <Cart />
                </Col>
              </Row>
            </SecondaryView>
          </>
        )}
      </SplitView>
    </>
  )
}

export default withContext(List, {
  mapProps: (context: WrContextType) => {
    const catalogId = context.constants.client.catalogIds[0]
    const mainCatalog = context.state.catalogs.find((c) => c.id === catalogId)
    return {
      catalogDescription: mainCatalog?.description,
      updateProductsToDisplay: context.actions.updateProductsToDisplay,
      productsToDisplay: context.state.productsToDisplay,
      updateTab: context.actions.updateTab,
      currentTab: context.state.currentTab,
    }
  },
})
