export const svgToImage = (svg: SVGSVGElement) =>
  new Promise((resolve: (resovle: GanttChartDataType) => void) => {
    // copied from `npm:svg-to-dataurl`
    const encoded = encodeURIComponent(svg.outerHTML).replace(/'/g, '%27').replace(/"/g, '%22')
    const header = 'data:image/svg+xml,'
    const svgDataUrl = header + encoded
    const { width, height } = svg.viewBox?.baseVal || { width: 100, height: 100 }
    const img = document.createElement('img')
    img.width = width
    img.height = height
    img.src = svgDataUrl

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d')?.drawImage(img, 0, 0, img.width * 0.7, img.height * 0.7) // scaling it down otherwise image is too large and gets cut off

      resolve({
        dataUrl: canvas.toDataURL(),
        width,
        height,
      })
    }
  })
