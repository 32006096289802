import { Product, Proposal, WrContextState } from '@tools/wr-catalog-base'
import { getAddonsFromCartItems } from '@routes/AddonsView/utils'

export const loadProposalIntoState = (state: WrContextState, proposal: Proposal, items?: Product[]) => {
  const phasesConfig = proposal?.settings?.phasesConfig
  state.existingProposal = proposal

  // Restore phasesConfig
  if (phasesConfig) {
    state.phasesConfig = phasesConfig
  }

  // Restore cart
  state.cart = (proposal?.settings.cart || [])
    .map((product) => {
      const item = items?.find((item) => item.id === product.id)
      if (!item) {
        return undefined
      }

      const itemObj = {
        ...product,
        id: item.id,
        cartId: product.cartId,
        description: item.description,
        name: item.name,
        rows: item.rows,
        values: product.values,
        selectedAddons: product.selectedAddons || [],
        price: product.price,
      }

      return itemObj
    })
    .filter(Boolean) as Product[]

  state.selectedAddons = getAddonsFromCartItems(state.cart)
}
