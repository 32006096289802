/* eslint-disable no-unused-expressions */
import { getCartId, Product, PhaseRowItemType, Addon } from '@tools/wr-catalog-base'

const getCompositeAddonKey = (addonParams: Partial<PhaseRowItemType>) => {
  return ['id', 'parentId', 'parentCartId', 'sourceRowId', 'sourceOptionId']
    .map((k) => addonParams[k as keyof Partial<PhaseRowItemType>])
    .join('|')
}

export const getAddonsFromSelections = (
  cart: Product[],
  allAddonItems: Product[] | undefined,
  existingSelectedAddons: Addon[],
) => {
  const addonParams: Addon[] = []

  cart.forEach((item) => {
    item.rows.forEach((row) => {
      if (!row.options?.usesAddOnItems) return

      item.values[row.id]?.forEach?.((selection: string) => {
        const parsedSelection = JSON.parse(selection)
        const parsedValue = JSON.parse(parsedSelection.value)

        parsedValue.items?.forEach((conf: PhaseRowItemType) => {
          if (!conf.requireConfiguration) return

          addonParams.push({
            id: conf.itemId as string,
            parentId: item.id,
            parentCartId: item.cartId,
            sourceRowId: row.id,
            sourceOptionId: parsedSelection.id,
          })
        })
      })
    })
  })

  const existingSelectedAddonsByKey = (existingSelectedAddons || []).reduce(
    (memo: { [key: string]: PhaseRowItemType }, addon) => {
      const key = getCompositeAddonKey(addon)
      memo[key] = memo[key] || addon
      return memo
    },
    {},
  )
  const selectedAddons = addonParams.map((params) => {
    const existingAddon = existingSelectedAddonsByKey[getCompositeAddonKey(params)]
    if (existingAddon) return existingAddon

    const addon = allAddonItems?.find((addon) => {
      return addon.id === params.id
    })

    return {
      ...addon,
      ...params,
      addonId: getCartId(),
    }
  })
  return selectedAddons as PhaseRowItemType[]
}

export const getAddonsFromCartItems = (cart: Product[]) => {
  let selectedAddons: PhaseRowItemType[] = []

  cart.forEach((cartItem) => {
    if (cartItem.selectedAddons) {
      selectedAddons = [...selectedAddons, ...cartItem.selectedAddons]
    }
  })

  return selectedAddons
}
