import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DocumentEditor } from '@onlyoffice/document-editor-react'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import { cloneDeep, merge } from 'lodash'
import type IConfig from '@onlyoffice/document-editor-react/dist/esm/model/config'
import {
  context,
  DocumentBuilderExportOptions,
  DocumentEditorToolbar,
  DocumentEditorWrapper,
  Product,
  Progress,
  request,
  useDocumentBuilderApi,
} from '@tools/wr-catalog-base'
import { getSelectedTemplate } from 'utils/proposals/getSelectedTemplate'
import { getBuilderContext } from 'utils/proposals/getBuilderContext'
import { fetchSOW } from 'utils/proposals/fetchSOW'
import { ModalDescription } from '@routes/DocumentEdit/style'

const WORKRAILS_PLUGIN_UUID = 'asc.{3bc88376-78f0-4512-9220-6385697e20dc}'

interface GetConfigProps extends Partial<IConfig> {
  fileName: string
  attachmentId: string
  url: string
  disabled?: boolean
  onDownloadAs?: (event: object) => void
}

const getConfig = (
  { fileName, url, attachmentId, disabled = false, onDownloadAs, ...rest }: GetConfigProps,
  userId: string,
) =>
  merge<IConfig, IConfig>(
    {
      document: {
        fileType: 'docx',
        key: `${nanoid(23)}`,
        title: fileName,
        url,
        permissions: {
          edit: !disabled,
          review: true,
          comment: true,
        },
      },
      editorConfig: {
        callbackUrl: `${context.constants.appHost}/api/v1/onlyoffice/track?attachmentId=${attachmentId}`,
        user: {
          id: userId,
          name: `WorkRails User`,
        },
        customization: {
          compactHeader: false,
          uiTheme: 'theme-workrails',
          toolbarNoTabs: false,
          integrationMode: 'embed',
          forcesave: true,
          logo: {
            image: `${window.location.origin}/new-logo-white.png`,
          },
          customer: {
            logo: `${window.location.origin}/new-logo.png`,
            name: 'WorkRails Inc.',
            www: 'https://workrails.com',
          },
          // @ts-ignore
          loaderLogo: `${window.location.origin}/new-logo-small.png`,
          loaderName: 'Document Editor',
        },
        plugins: {
          autostart: [WORKRAILS_PLUGIN_UUID],
        },
      },
      events: {
        ...(onDownloadAs && { onDownloadAs }),
      },
      documentType: 'word',
    },
    rest,
  )

const EditorContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  & iframe {
    min-height: 100vh;
  }
`

const DOCUMENT_TYPES = {
  DOCUMENT_TEMPLATE: 'documentTemplate',
  DOCUMENT_PROPOSAL: 'documentProposal',
} as const

interface Event {
  id: string
  name: string
  data: any
}

const EVENTS = {
  WORKRAILS_PLUGIN_READY: 'WORKRAILS_PLUGIN_READY',
  VALUES_RECEIVED: 'VALUES_RECEIVED',
} as const

const createEvent = (name: string, data: any): Event => ({ id: nanoid(), name, data })

export const withOnlyOfficeEditor = (OldEditor: React.ComponentType<any>) => (props: React.PropsWithChildren<any>) => {
  const [config, setConfig] = useState<IConfig | null>(null)
  const [token, setToken] = useState<string>('')
  const { usesOnlyOffice, editorUrl } = props.context.state.companySettings
  const [events, setEvents] = useState<Event[]>([])
  const [editor, setEditor] = useState<any>()
  const [iframe, setIframe] = useState<HTMLIFrameElement>()

  const getTagValues = (cart: Product[]) => {
    const product = cart?.[0]

    const rows = product?.rows.reduce((data, row) => {
      if (!row.options?.tag) return data
      const parse = (v: unknown) => (typeof v === 'string' && v[0] === '{' ? JSON.parse(v) : v)
      const value = parse(product?.values[row.id])
      return {
        ...data,
        [row.options?.tag]: Array.isArray(value) ? value.map(parse) : value,
      }
    }, {})
    return {
      ...rows,
      Total: product?.price,
    }
  }

  const onEditorMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== editorUrl) return
      if (!config) return

      const data = JSON.parse(event.data)
      if (data.event !== EVENTS.WORKRAILS_PLUGIN_READY) {
        return
      }

      const key = config.document.key!
      setEditor(window.DocEditor.instances[key])
      setIframe(document.querySelector(`#editor-container-${key} > iframe`) as HTMLIFrameElement)
    },
    [editorUrl, config],
  )
  const editorRef = useRef<any>()

  const addEvent = (event: Event) => setEvents((events) => [...events, event])

  useEffect(() => {
    if (!iframe) return
    events.forEach((event) => {
      iframe.contentWindow!.postMessage(JSON.stringify(event), editorUrl)
      setEvents((events) => events.filter(({ id }) => id !== event.id))
    })
  }, [events, iframe])

  const templateSelected = getSelectedTemplate(props.context)
  const onDownloadAs = async (event: any) => {
    window.open(event.data.url)
    context.actions.closeModal()
    context.history.push(context.getRoutePath('proposals'))
  }

  const makeConfig = async () => {
    const templateAttachment = await request({
      url: `${context.constants.appHost}/api/v1/attachments/attachable-id/${templateSelected?.value}?internalType=${DOCUMENT_TYPES.DOCUMENT_TEMPLATE}`,
    })

    const duplicatedAttachment = await request({
      url: `${context.constants.appHost}/api/v1/attachments/attachable-id/${templateSelected?.value}?internalType=${DOCUMENT_TYPES.DOCUMENT_PROPOSAL}`,
      method: 'POST',
      body: JSON.stringify({
        duplicateFrom: templateAttachment.data[0].id,
      }),
    })

    const attachmentId = duplicatedAttachment.data[0].id
    const proposalAttachments = await request({
      url: `${context.constants.appHost}/api/v1/attachments/id-list?internalType=${DOCUMENT_TYPES.DOCUMENT_PROPOSAL}`,
      method: 'POST',
      body: JSON.stringify({
        data: [attachmentId],
      }),
    })

    const [proposalAttachment] = proposalAttachments.data
    const config = getConfig(
      {
        fileName: JSON.parse(proposalAttachment.meta).fileName,
        url: proposalAttachment.path,
        attachmentId,
        onDownloadAs,
      },
      context.state.user?.id!,
    )

    const response = await request({
      url: `${context.constants.appHost}/api/v1/onlyoffice/token`,
      method: 'POST',
      body: JSON.stringify({
        data: {
          config,
        },
      }),
    })

    const token = response.data.token
    setConfig(config)
    setToken(token)
  }

  useEffect(() => {
    if (!usesOnlyOffice) {
      return
    }
    makeConfig()
    addEvent(createEvent(EVENTS.VALUES_RECEIVED, getTagValues(context.state.cart)))
  }, [usesOnlyOffice])

  useEffect(() => {
    window.addEventListener('message', onEditorMessageHandler)
    return () => {
      window.removeEventListener('message', onEditorMessageHandler)
    }
  }, [onEditorMessageHandler])

  useEffect(() => {
    editorRef.current = editor
  }, [editor])

  const builderConfig = useMemo(
    () => ({
      getContext: getBuilderContext,
      getTemplate: () => fetchSOW(templateSelected?.value),
      save({ options }: { options: DocumentBuilderExportOptions }) {
        context.actions.openModal({
          showClose: false,
          body: (
            <>
              <ModalDescription>We are generating your proposal</ModalDescription>
              <Progress />
            </>
          ),
        })

        editorRef.current.downloadAs(options.type)
      },
    }),
    [templateSelected?.value],
  )

  const api = useDocumentBuilderApi(builderConfig)
  if (!usesOnlyOffice) {
    return <OldEditor {...props} />
  }

  return (
    <DocumentEditorWrapper builder={api}>
      <DocumentEditorToolbar enableChangeLog options={{}} />
      {token !== '' && config !== null && (
        <EditorContainer id={`editor-container-${config.document.key!}`}>
          <DocumentEditor
            id={config.document.key!}
            documentServerUrl={editorUrl}
            config={
              // OnlyOffice mutates the passed in object
              cloneDeep({ ...config, token })
            }
          />
        </EditorContainer>
      )}
    </DocumentEditorWrapper>
  )
}
