import { context, phasesModule, Product } from '@tools/wr-catalog-base'
import { getItemSpecs } from './getItemSpecs'
import { getPhaseCostOptions } from './getPhaseCostOptions'
import getProductPrice from './getProductPrice'

export const calculateFinalPrice = (item: Product, withDiscount = true): number => {
  const { phasesConfig } = context.state
  const { totalPrice } = getItemSpecs().find((phaseItem) => phaseItem.id === item.id) ?? {}

  const phaseCostOptions = getPhaseCostOptions()

  const phaseItems = phasesConfig?.itemGroups
    .map((itemGroup) => {
      const newItemGroup = { ...itemGroup }
      newItemGroup.itemRows = itemGroup.itemRows.filter((itemRow) => itemRow.item.parentId === item.id)

      return newItemGroup
    })
    .filter((itemGroup) => itemGroup.itemRows.length > 0)

  const itemPrice = phaseItems
    ? phasesModule.utils.calculatePhaseConfigCosts({ itemGroups: phaseItems }, phaseCostOptions).cost
    : null

  const priceKey = withDiscount ? 'discountedCost' : 'fullCost'

  if (itemPrice?.[priceKey]) {
    return itemPrice[priceKey] / 100
  }

  const normalPrice = getProductPrice(item, withDiscount)

  if (isNaN(normalPrice)) {
    return (totalPrice?.[priceKey] || 1) / 100
  }

  return normalPrice
}
