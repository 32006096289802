import { configureContext, context, isAnalyticsEnabled, Product, updateContextState } from '@tools/wr-catalog-base'
import routes from '@routes/routes'
import config from './config'
import * as actions from './actions'
import mixpanel from 'mixpanel-browser'

export const initStore = () =>
  configureContext({
    ...config,
    state: {
      ...config.state,
      productsRows: {},
      cartCalculations: {
        totalPrice: 0,
        discount: 0,
        totalHours: 0,
        totalWeeks: 0,
      },
      cartForm: null,
    },
    actions: {
      ...actions,
      removeProductFromCart: (productId: string, product: Product) => {
        updateContextState((state) => {
          const {
            cart: { multiple },
          } = context.constants
          const productIndex = multiple ? state.cart.indexOf(product) : state.cart.findIndex((v) => v.id === productId)

          if (productIndex !== -1) {
            state.cart = [...state.cart.slice(0, productIndex), ...state.cart.slice(productIndex + 1)]
          }

          // The original comment form Nikolay Belchev from https://github.com/workrails/workfusion/pull/70:
          // Used to unset itemRows when a product is removed from cart
          // Phase item rows on WF get set on the ProductDetails comp's level
          // and represent the addons of the particular product
          // example: Evelyn, Tara, Darryl are all addons of the Digital Worker item
          // (you can have only one item in the cart in WF)
          state.phasesConfig = {
            itemGroups: [{ itemRows: [] }],
          }
        })
        isAnalyticsEnabled() &&
          mixpanel.track(context.constants.analytics.serviceRemovedFromCart, { serviceId: productId })
      },
    },
    routes,
  })
