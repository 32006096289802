import { safeJsonParse } from '@tools/wr-catalog-base'

export const getTamProductPrice = ({
  getPricingConstantByName,
  checkIfNotNumericField,
  formValues,
  product,
}: GetProductPriceContext) => {
  let totalHours = 1
  let quartersSelected: {
    amount?: number
    values?: {
      quarter: number
      hours?: number
    }
  } = {}

  let hoursSelected: {
    amount?: number
    values?: any
  } = {}

  const selectedQuartersRegex = /(\d+.)Quarter/i
  const selectedHoursRegex = /(\d+.)Hours/i

  Object.keys(formValues).forEach((key) => {
    if (formValues[key].length && checkIfNotNumericField(product.rows?.find((item) => item.id === key))) {
      const parsedValue = safeJsonParse(formValues[key], { label: '', value: '' })
      const quarters = selectedQuartersRegex.exec(parsedValue.label)
      const hours = selectedHoursRegex.exec(parsedValue.label)

      if (quarters) {
        quartersSelected = {
          amount: +quarters[1],
          values: safeJsonParse(parsedValue.value, undefined),
        }
      }

      if (hours) {
        hoursSelected = {
          amount: +hours[1],
          values: safeJsonParse(parsedValue.value, undefined),
        }
      }
    }
  })

  if (Object.keys(quartersSelected).length && Object.keys(hoursSelected).length) {
    totalHours = hoursSelected.values.find((el: { quarter: number }) => el.quarter === quartersSelected.amount)?.hours

    const priceConst = getPricingConstantByName(`q_${quartersSelected?.amount}_hours_${hoursSelected?.amount}`)?.value

    return (Number(priceConst) * totalHours) / 100
  }

  return 0
}
