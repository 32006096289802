import { context, toBase64, ProposalSubmissionType, CartItem, getProductRow, RowVariants } from '@tools/wr-catalog-base'
import { fetchOrCreate } from 'utils/salesforce/fetchOrCreate'
import { previewDoc } from './previewDoc'
import { getCartTotalPrice } from 'utils/product/getCartTotalPrice'
import { RecordAttributes } from 'jsforce'
import getProductPrice from 'utils/product/getProductPrice'
import { stripHtmlTags } from 'utils/html/stripHtmlTags'

const createQuoteLines = async ({
  cart,
  quoteId,
  opportunityId,
}: {
  cart: CartItem[]
  quoteId: string
  opportunityId: string
}) => {
  const sfConn = context.constants.getSfConn()

  // @refactor
  // Get the quote
  const sfQuote = (await fetchOrCreate(
    'SBQQ__Quote__c',
    { Id: quoteId },
    {
      SBQQ__Opportunity2__c: opportunityId,
    },
  )) as { Id?: string | undefined; attributes?: RecordAttributes | undefined }

  /// @refactor
  quoteId = sfQuote?.Id as string

  // Delete previous quote lines
  // @refactor
  await sfConn
    .sobject('SBQQ__QuoteLine__c')
    .find({ SBQQ__Quote__c: quoteId })
    .then((data) => {
      return Promise.all(
        data.map((v) => {
          return v.Id && sfConn.sobject('SBQQ__QuoteLine__c').destroy(v.Id)
        }),
      )
    })

  // Create quote lines
  await Promise.all(
    cart.map(async (item) => {
      // Get the SF product
      const sfProduct = (await fetchOrCreate(
        'Product2',
        { Name: item.name },
        {
          Name: item.name,
          // ProductCode: '???'
        },
      )) as { Id?: string | undefined; attributes?: RecordAttributes | undefined }

      await sfConn.sobject('SBQQ__QuoteLine__c').create({
        SBQQ__Quote__c: quoteId,
        // SBQQ__NetPrice__c: getProductPrice(item),
        SBQQ__NetPrice__c: getProductPrice(item, true),
        SBQQ__Product__c: sfProduct.Id,
      })
    }),
  )
}

const postSfData = async ({ doc }: { doc: Blob }) => {
  const sfConn = context.constants.getSfConn()
  const { cart, cartCalculations } = context.state
  const { opportunityId, quoteId } = context.constants.sfData
  const mainProduct = cart[0]
  const sfFields = mainProduct?.rows
    .filter((v) => v.options && v.options.tag && v.options.tag.includes('salesForce'))
    .map((v) => ({ [v.label]: v.control }))
    .reduce((acc, v) => ({ ...acc, ...v }), {})

  console.log('sfFields', sfFields)

  const totalPrice = cart.reduce((acc, v) => {
    return acc + getProductPrice(v, true)
  }, 0)

  const projectDescription = cart
    .map((product) => {
      const row = product.rows.find((row) =>
        row.options.tag
          ?.split(',')
          .map((v) => v.trim())
          ?.includes('project_description'),
      )

      return stripHtmlTags((row?.rawValue as { value: string })?.value || '')
    })
    .filter(Boolean)

  const totalHoursAndWeeks = `
    <p><strong>Total Hours:</strong> ${cartCalculations.totalHours}</p>
    <p><strong>Total Weeks:</strong> ${cartCalculations.totalWeeks}</p>
  `

  await Promise.all([
    sfConn.sobject('Opportunity').update({
      Id: opportunityId,
      Scope_Description__c: totalHoursAndWeeks,
      Description: projectDescription.join('\n\n'),
    }),
    sfConn.sobject('OpportunityLineItem').create([
      {
        OpportunityId: opportunityId,
        Quantity: 1,
        TotalPrice: totalPrice,
        ServiceDate: new Date(),
        Description: 'WR SOW', // placeholder
        Product2Id: '01t3t000004YHPcAAO',
        PricebookEntryId: '01u3t00000STv8FAAT',
      },
    ]),
  ])

  // @refactor
  try {
    const contentVersion = (await sfConn.sobject('ContentVersion').create({
      Title: 'SOW',
      PathOnClient: 'SOW.pdf',
      ContentLocation: 'S',
      VersionData: await toBase64(doc, true),
    })) as { id: string }

    const {
      records: [contentDocumentId],
    } = (await sfConn.query(
      `SELECT ContentDocumentId FROM ContentVersion WHERE Id = '${contentVersion.id}'`,
    )) as unknown as {
      records: { ContentDocumentId: string }[]
    }

    await sfConn.sobject('ContentDocumentLink').create({
      ContentDocumentId: contentDocumentId.ContentDocumentId,
      LinkedEntityId: opportunityId,
      Visibility: 'AllUsers',
    })
  } catch (e) {
    console.log('error', e)
  }

  try {
    await createQuoteLines({ cart, quoteId, opportunityId })
  } catch (err) {
    console.log(err)
  }

  // Redirect back to the Opportunity record
  context.actions.closeModal()
}

export const submitProposal = async (
  html: string | null,
  { asDraft, asQuotePdf, asQuoteWord, formValues, fromDecisionTree }: Partial<ProposalSubmissionType>,
) => {
  const { actions } = context

  formValues = {
    name: '',
    company: '',
    email: '',
    date: '',
  }

  const clientName = getProductRow(context.state.cart[0], RowVariants.Tag, 'companyName')?.value

  if (!asDraft && html) {
    const { selectedAddons, cartCalculations } = context.state

    const res = await actions.generateProposal({
      asDraft,
      asQuotePdf,
      asQuoteWord,
      formValues,
      fromDecisionTree,
      html,
      noTagReplacement: true,
      proposalName: 'sow',
      requiresAuth: false,
      ...(selectedAddons?.length && { value: cartCalculations.totalPrice ?? 0 }),
      settings: {
        clientName: clientName,
        html,
        opportunityId: context.constants?.sfData?.opportunityId,
        value: 100 * getCartTotalPrice(context.state.cart),
        companyName: 'WorkRails',
        accountId: context.state.sfAccount?.Id,
        accountName: context.state.sfAccount?.Name,
        quoteId: context.state.sfQuote?.Id,
        quoteName: context.state.sfQuote?.Name,
        opportunityName: context.state.sfOpportunity?.Name,
        cart: context.state.cart.map((product) => ({
          id: product.id,
          cartId: product.cartId,
          values: product.values,
          selectedAddons: product.selectedAddons || [],
          price: product.price,
        })),
        phasesConfig: context.state.phasesConfig,
        currencyIsoCode: context.state.sfOpportunity?.currencyIsoCode,
        createdByName: context.state.sfUser?.name || '',
      },
      callback: () => {},
    })

    if (res?.[0]?.doc) {
      const [{ doc }] = res

      if (context.constants?.sfData?.opportunityId) {
        await postSfData({ doc })
      }
    }

    context.actions.closeModal()
    context.history.push(context.getRoutePath('proposals'))
  } else {
    await previewDoc({ html: html || '' })
    actions.closeModal()
  }
}
