import React from 'react'
import { FiPaperclip } from 'react-icons/fi'
import {
  Proposals,
  Row,
  Grid,
  SpacedCol,
  Link,
  i18n,
  withContext,
  connectRequest,
  context,
  styled,
  Proposal,
  WrContextType,
  PromiseState,
  ProposalListColumn,
} from '@tools/wr-catalog-base'
import ActionsDropdown from './ActionsDropdown'
import { isInSalesForce } from 'utils/salesforce/isInSalesForce'

const GridWrapper = styled.div`
  td:nth-child(4) {
    width: 200px;
  }
`

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 7px;
  min-width: 155px;
  max-width: 155px;
  font-size: 16px;
  margin: 0 auto;
  color: #fdfdff;
  background-color: #ffa633;

  &[data-status='Approved'] {
    background-color: ${({ theme }: { theme: typeof context.theme }) => theme.palette.primary};
  }

  &[data-status='Rejected'] {
    background-color: #e84652;
  }
`

const ViewContext = React.createContext({
  opportunities: [] as Opportunity[],
})

const CLIENT_NAME_COL = {
  id: 'settings.clientName',
  label: i18n('proposals.columns.clientName'),
  component: function ClientName({ row }: { row: Proposal }) {
    const { opportunities } = React.useContext(ViewContext)
    const opp = opportunities.find((opp) => opp.Id === row.settings.opportunityId)

    return (
      <>
        <div>
          {opp?.Account?.Name || row.settings.clientName || 'Client'}{' '}
          {row.quote && (
            <Link href={row.quote}>
              <FiPaperclip />
            </Link>
          )}
        </div>
      </>
    )
  },
}
const OPPORTUNITY_COL: ProposalListColumn = {
  id: 'settings.opportunityId',
  label: 'Opportunity',
  // @refactor check if value is used at all
  component: function OpportunityLink({ value }: any) {
    const { opportunities } = React.useContext(ViewContext)

    if (!value) return <>No SFDC opportunity ID</>

    const opp = opportunities.find((opp) => opp.Id === value)

    return (
      <a href={`${window.location.origin}/${value}`} target="_top">
        {opp?.Name || value}
      </a>
    )
  },
}
const ACTIONS_COLUMNS: ProposalListColumn = {
  id: 'actions',
  label: i18n('proposals.columns.actions'),
  component: ActionsDropdown,
}

const getColumns = (defaultColumns: ProposalListColumn[]): ProposalListColumn[] => {
  let remappedColumns = defaultColumns.map((col) => {
    if (col.id === 'status') {
      col.component = ({ row }: { row: Proposal }) => {
        const rowStatus = localStorage.getItem(row.id)

        return (
          <StyledStatus data-status={rowStatus} data-rowId={row.id}>
            {rowStatus ?? 'Pending'}
          </StyledStatus>
        )
      }
    }

    return col
  })

  let columns: ProposalListColumn[] = ['createdAt', 'settings.value', 'status']
    .map((id) => remappedColumns.find((dc) => dc.id === id) as ProposalListColumn)
    .filter((col) => Boolean(col))

  const result: ProposalListColumn[] = [CLIENT_NAME_COL, ...columns]

  if (isInSalesForce()) result.push(OPPORTUNITY_COL)

  result.push(ACTIONS_COLUMNS)

  return result.filter((d) => d)
}

interface ProposalViewProps {
  opportunitiesFetch: PromiseState<Opportunity[]>
}

const ProposalsView = (props: ProposalViewProps) => {
  const { opportunitiesFetch } = props

  return (
    <GridWrapper>
      <Grid>
        <Row>
          <SpacedCol xs={12}>
            <ViewContext.Provider value={{ opportunities: opportunitiesFetch.value || [] }}>
              <Proposals getColumns={getColumns} paginated isSortable searchbox />
            </ViewContext.Provider>
          </SpacedCol>
        </Row>
      </Grid>
    </GridWrapper>
  )
}

export default withContext(
  // @refactor remove any
  connectRequest<any>((props: { context: WrContextType }) => {
    const oppIds = props.context.state.proposals.map((p) => p.settings.opportunityId).filter((d) => d)

    return {
      opportunitiesFetch:
        context.constants.requireSsoAuth || process.env.REACT_APP_WR_TEST
          ? {
              comparison: oppIds.sort().join(';'),
              value: () => {
                if (!oppIds.length) return []

                return props.context.constants
                  .getSfConn()
                  .sobject('Opportunity')
                  .find({ Id: oppIds })
                  .select('Id, Name, Account.Id, Account.Name')
              },
            }
          : {
              value: null,
            },
    }
  })(ProposalsView),
)
