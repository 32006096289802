import {
  context,
  request,
  yup,
  salesforceConnectionMonkeyPatch,
  ProductRowType,
  AnySchema,
  SalesforceWorkflow,
  WorkflowBuilder,
  WorkflowUnits,
  mockSfConn,
} from '@tools/wr-catalog-base'
import jsforce from 'jsforce'

import routes from '@routes/routes'
import * as actions from './actions'
import getProductPrice from '../utils/product/getProductPrice'
import { theme } from './theme'
import { DEFAULT_OPPORTUNITY_ID } from './consts'
import type { WrBox } from '../types/global'

const isInSalesForce = Boolean(window.wrBox)

if (process.env.NODE_ENV === 'development' && !window.wrBox) {
  window.wrBox = {
    opportunityId: DEFAULT_OPPORTUNITY_ID,
    catalogId: process.env.REACT_APP_CATALOG_ID as string,
    quoteId: '',
  } as WrBox
}

const sfConn = mockSfConn

const preProdDomain = process.env.REACT_APP_PRE_PROD_HOST

const Workflow = (() => {
  // Build bare workflow with SF integration and basic data fetching, but nothing else
  const builder = new WorkflowBuilder().template(SalesforceWorkflow).only(WorkflowUnits.InitializationUnit)

  return builder.build()
})()

/** @type {import('@tools/wr-catalog-base').context} */
export default {
  hashHistory: Boolean(window.wrBox),
  actions: {
    ...actions,
  },
  state: {
    activeSorting: false,
    productsRows: {},
    templates: [],
    templateSelected: null,
    totalCartValueWithAddons: 0,
    phasesConfig: {
      itemGroups: [
        {
          itemRows: [],
        },
      ],
    },
    workflow: Workflow,
  },
  theme,
  constants: {
    requireAuth:
      process.env.NODE_ENV !== 'development' &&
      process.env.REACT_APP_USES_SSO !== 'yes' &&
      process.env.REACT_APP_USES_AUTH === 'yes',
    requireSsoAuth: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_USES_SSO === 'yes',
    requirePass: process.env.REACT_APP_USES_PASSWORD === 'yes',
    // preProd: {
    //   strategy: isInSalesForce ? 'LocalStorage' : 'Default',
    //   domain: preProdDomain,
    //   allowedUsers: process.env.REACT_APP_PRE_PROD_USERS.split(','),
    //   isInPreProdMode: isInSalesForce
    //     ? Boolean(localStorage.getItem('wr_is_pre_prod'))
    //     : window.location.origin === preProdDomain,
    // },
    sso: {
      publicUrl: process.env.PUBLIC_URL,
      host: process.env.REACT_APP_SSO_HOST,
      newWindowFlow: true,
      onAuthResolved: () => {
        const sfConn = new jsforce.Connection({
          instanceUrl: 'https://na123.lightning.force.com',
          proxyUrl: `${context.constants.sso.host || context.constants.appHost}/api/v1/sso/act`,
        })
        // FIXME: Workaround
        if (context.state.user) context.state.user.id = undefined as any
        salesforceConnectionMonkeyPatch(sfConn)
        context.constants.getSfConn = () => sfConn
      },
    },
    token: process.env.REACT_APP_WR_ACCESS_TOKEN,
    host: process.env.REACT_APP_WR_APP_HOST,
    appHost: process.env.REACT_APP_WR_APP_HOST,
    transactionsEnabled: true,
    client: {
      restrictLogin: true,
      emailDomains: ['@workrails.com'],
      catalogIds: [window.wrBox && window.wrBox.catalogId ? window.wrBox.catalogId : process.env.REACT_APP_CATALOG_ID],
      companyId: process.env.REACT_APP_WR_COMPANY_ID,
      addOnCatalogId: process.env.REACT_APP_WR_ADDON_CATALOG_ID,
    },
    cart: {
      ...context.constants.cart,
      multiple: false,
    },
    overrideProductValidationSchema: (validations: { [key: string]: AnySchema }, validatableRows: ProductRowType[]) => {
      const discountRow = validatableRows.filter((item) => item.label === 'Discount')

      if (discountRow.length) {
        validations[discountRow[0].id] = yup
          .number()
          .min(0, 'Minimum discount value - 0')
          .max(20, 'Maximum discount value - 20')
      }
    },
    sfData: window.wrBox || {},
    getSfConn: () => sfConn,
    proposals: {
      requests: {
        fetchProposals: {
          shouldFetchByCompanyId: true,
        },
      },
    },
    product: {
      list: {
        gridColSizes: { sm: 6, md: 4, lg: 4 },
      },
    },
    editor: {
      ...context.constants.editor,
      config: {
        ...context.constants.editor.config,
        content_style: 'body { font-size: 13px }',
      },
    },
  },
  utils: {
    product: {
      getListPrice: () => 0,
      getPrice: getProductPrice,
    },
    proposals: {
      requests: {
        createPDFRequest: ({ html }: { html: string }) =>
          request({
            url: `${context.constants.appHost}/api/v1/documents/export`,
            method: 'POST',
            body: JSON.stringify({
              data: {
                html,
                options: {
                  media: 'print',
                },
              },
            }),
          }),
      },
    },
  },
  routes,
}
