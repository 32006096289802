import { Product } from '@tools/wr-catalog-base'

export const getProductDiscount = (product: Product) => {
  let discount = 0

  product.rows.forEach((row) => {
    if (row.options?.tag !== 'discountPercent') return

    discount = discount || Number(product.values?.[row.id]) || 0
  })

  if (discount > 20) return 0

  return Math.floor(discount) || 0
}
