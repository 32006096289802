import { documentStyles } from './documentStyles'
import { tableStyles } from './tableStyles'
import { documentFontFamily } from './documentFontFamily'
import { addExhibitsToTable } from './addExhibitsToTable'
import { addExhibits } from './addExhibits'
import { customSowTagReplacer, DocumentBuilderContext } from '@tools/wr-catalog-base'

const setupHeaders = (documentContainer: HTMLElement, innerHtml: string, isPdf: boolean) => {
  const regex = /&lt;sowHeader&gt;(.*?)&lt;\/sowHeader&gt;/s
  const header = (regex.exec(innerHtml) || [])[1]
  if (header) {
    innerHtml = innerHtml.replace(regex, '')
  }
  const hasHeader = header && isPdf
  if (hasHeader) {
    innerHtml = `
      <div style="position: fixed; top: 0; left: 0; right: 0; height: 100px; text-align: center;">
        ${header}
      </div>
      <table>
        <thead><tr><td><div style="height: 50px">&nbsp;</div></td></tr></thead>
        <tbody>
          <tr>
            <td class="main-content">
              ${innerHtml}
            </td>
          </tr>
        </tbody>
        <tfoot><tr><td><div style="height: 50px">&nbsp;</div></td></tr></tfoot>
      </table>
    `
  }

  documentContainer.innerHTML = innerHtml
  return hasHeader
}

export const finalizeHtml = (ctx: DocumentBuilderContext) => {
  const { exportOptions } = ctx

  const htmlContainer = document.createElement('html')
  let documentContainer = document.createElement('body')

  const head = document.createElement('head')
  head.innerHTML = `
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <title>Workrails Catalog</title>
  `

  const hasHeader = setupHeaders(documentContainer, ctx.html.toString(), exportOptions.type === 'pdf') as boolean

  documentStyles(head, hasHeader)
  documentFontFamily(head)

  htmlContainer.appendChild(head)
  htmlContainer.appendChild(documentContainer)

  ctx.html = `
    <!DOCTYPE html>
    ${htmlContainer.outerHTML}
  `
}

export const updateHtml = (ctx: DocumentBuilderContext) => {
  const { html, cart, fromDecisionTree, formValues, totalWeeks, totalHours, ganttChartData } = ctx
  if (!document) return

  let documentContainer = document.createElement('div')

  documentContainer.innerHTML = html

  Array.from(documentContainer.querySelectorAll('ol')).forEach((list) => {
    list.style.paddingLeft = '15px'
  })
  Array.from(documentContainer.querySelectorAll('li p > span:first-child')).forEach((span) => {
    ;(span as HTMLSpanElement).style.paddingLeft = '15px'
  })

  tableStyles(documentContainer)

  const resultHTML = customSowTagReplacer(cart || [], documentContainer.innerHTML, {
    additionalQuickReplaceFieldsWithDefaultImplementation: {
      currentDate: () => new Date().toLocaleDateString(),
      'total hours': () => totalHours,
      'Page Break': () =>
        '<span class="pagebreak" clear="all" style="page-break-before: always; display: block;">&nbsp;</span>',
      gantt: () =>
        `<img src="${ganttChartData?.dataUrl}" width="${ganttChartData?.width}" height="${ganttChartData?.height}"`,
    },
  })

  documentContainer.innerHTML = resultHTML
  documentContainer = addExhibitsToTable(documentContainer, fromDecisionTree)
  documentContainer = addExhibits(documentContainer, fromDecisionTree, formValues, totalWeeks, totalHours)

  ctx.html = documentContainer.innerHTML
}
