import { Product, FormikValues } from '@tools/wr-catalog-base'

export const attachNoteToRows = (formValues: FormikValues, product: Product) => {
  const notes = Object.keys(formValues).filter((key) => key.includes('_notes'))

  product.rows.forEach((row) => {
    row.notes = undefined
  })

  notes.forEach((note) => {
    const [rowId, itemId] = note.split('_')

    const row = product.rows.find((row) => row.id === rowId)

    if (!row) return

    const item = row.options?.items?.find((item) => item.id === itemId)

    if (!item) return

    if (!row.notes) row.notes = []

    row.notes.push({
      id: item.id,
      label: item.label,
      value: item.value,
      notesType: item.notesType,
      isChecked: item.isChecked,
      allowNotes: item.allowNotes,
      skipTo: item.skipTo,
      noteValue: formValues[note],
    })
  })
}
