export const tableStyles = (container: HTMLElement) => {
  const tablesTds = container.querySelectorAll<HTMLElement>('table td')
  const tablesTdsText = container.querySelectorAll<HTMLElement>('table td p span')

  const myTds = Array.from(tablesTds).filter((td) => {
    return (
      td.textContent === '<Name>' ||
      td.textContent === '<Proposed Start Date>' ||
      td.textContent === '<Company>' ||
      td.textContent === '<Email>' ||
      td.textContent === 'Proposed Start Date:' ||
      td.textContent === 'Prepared for:' ||
      td.textContent === 'Company:' ||
      td.textContent === 'Email:'
    )
  })

  let myTdsText = Array.from(tablesTdsText).filter((td) => {
    return (
      td.textContent === 'Prepared For:' ||
      td.textContent === 'Company:' ||
      td.textContent === 'Proposed Start Date:' ||
      td.textContent === 'Email:'
    )
  })

  myTds.forEach((td) => {
    td.style.verticalAlign = 'middle'
    td.style.fontSize = '16px'
    td.style.fontFamily = 'Lato'
    td.style.color = '#666666'
    td.style.lineHeight = '28px'
    td.style.fontWeight = '600'
  })

  myTdsText.forEach((td) => {
    td.style.verticalAlign = 'middle'
    td.style.fontSize = '16px'
    td.style.fontFamily = 'Lato'
    td.style.color = '#666666'
    td.style.lineHeight = '28px'
    td.style.fontWeight = '600'
  })
}
