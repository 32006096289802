import { context, updateContextState } from '@tools/wr-catalog-base'

let previousValue: string | null = null

export const resetDiscounts = () => {
  updateContextState((state) => {
    state.phasesConfig.itemGroups.forEach((itemGroup) => {
      itemGroup.itemRows.forEach((itemRow) => {
        itemRow.item.discountAmount = 0
        itemRow.item.discountPercent = 0
      })
    })
  })
}

const onBack = (history: { pathname: string }) => {
  const currentValue = history.pathname

  if (previousValue === '/phases' && currentValue === '/add-ons') {
    resetDiscounts()
  }

  previousValue = currentValue
}

export const startPhasesOnBackListener = () => {
  context.history.listen(onBack)
}
