import { context, request } from '@tools/wr-catalog-base'

export const previewDoc = async ({ html }: { html: string }) => {
  const data = await request({
    url: `${context.constants.appHost}/api/v1/documents/export`,
    method: 'POST',
    body: JSON.stringify({ data: { html } }),
  })
  const file = new File([data], 'document.pdf', { type: 'application/pdf' })
  const url = URL.createObjectURL(file)
  window.open(url)
}
