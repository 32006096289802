/* eslint-disable no-unused-expressions */
import React, { Component, PropsWithChildren } from 'react'
import { context, Modal, Router, updateContextState, withContext, WrRoot } from '@tools/wr-catalog-base'
import DataResolver from '@components/DataResolver'

import Header from '@components/Header'
import './App.css'

import { initStore } from '@store'
import { PhaseCalculations } from '@components/PhaseCalculations'

initStore()

window.wrContext = context

class App extends Component {
  constructor(props: PropsWithChildren<{}>) {
    super(props)

    this.state = {
      viewStyle: '',
      menuOpened: false,
    }
  }

  render() {
    return (
      <DataResolver>
        <PhaseCalculations>
          <Header />
          <Router />
          <div data-testid="app-load-test" />
          <Modal />
        </PhaseCalculations>
      </DataResolver>
    )
  }
}

const ConnectedApp = withContext(App)

class AppRoot extends Component {
  render() {
    return (
      <WrRoot>
        <ConnectedApp />
      </WrRoot>
    )
  }
}

export default AppRoot
