import { Link, styled } from '@tools/wr-catalog-base'

export const DecisionTreeContainer = styled.div`
  .decision-tree-header {
    border-bottom: 1px solid #ddd;
  }

  .decision-tree-footer {
    border-top: 1px solid #ddd;
  }

  input,
  textarea,
  .react-date-picker__wrapper {
    border-radius: 5px;
  }

  .react-date-picker__wrapper {
    color: #333;
  }

  .react-date-picker__calendar-button svg {
    fill: #333;
  }
`

export const StylesWrapper = styled.div`
  font-size: 14px;
  margin-top: 22.5px;
  margin-bottom: 22.5px;

  .product-details-cart {
    margin-top: 9.7rem;
  }

  .wr__product-footer {
    button {
      line-height: 2.1rem;

      a {
        font-size: 12px;
      }
    }
  }

  .cart-heading {
    font-size: 21px !important;
    margin-bottom: 10px;
  }

  .categories__menu {
    font-size: 13px;

    label {
      font-size: 13px !important;
    }
  }
`

export const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 30px;
`
