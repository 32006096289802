export const documentStyles = (head: HTMLElement, noMargin?: boolean) => {
  const cssText = `
    body {
      padding: 0 50px;
      width: calc(100% - 100px);
      display: block;
    }

    .main-content > p:nth-child(3),
    body > p:nth-child(3) {
      margin-bottom: 60px;
    }

    .main-content > div:nth-child(18),
    body > div:nth-child(18) {
      width: calc(100% - 120px)
    }

    @page {
      margin: ${noMargin ? '0 0' : '50px 0'};
    }

    table { width:100%; page-break-inside:avoid; page-break-after:auto }
    table tr { page-break-inside:avoid; page-break-after:auto }
    table td { page-break-inside:avoid; page-break-after:auto }

    .recurring-table tr td:first-child,
    .software-table tr td:first-child,
    .one-time-table tr td:first-child {
      width: 75%!important;
    }
  `
  const style = document.createElement('style')

  style.innerHTML = cssText
  head.appendChild(style)
}
