/* eslint-disable no-unused-expressions */
import {
  updateContextState,
  getCartId,
  Product,
  safeJsonParse,
  ParsedRowOptionItemValue,
  PhaseItemRowType,
  PhaseRowItemType,
  ProductRowType,
  uniqueArrayById,
  isAnalyticsEnabled,
  context,
} from '@tools/wr-catalog-base'
import { debounce } from 'lodash'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import mixpanel from 'mixpanel-browser'

export const getCompositeAddonKey = (addonParams: { [key: string]: any }) => {
  return ['id', 'parentId', 'parentCartId', 'sourceRowId', 'sourceOptionId'].map((k) => addonParams[k]).join('|')
}

const debouncedClickTracking = debounce((currentTab: string) => {
  if (currentTab === 'secondary') mixpanel.track(context.constants.analytics.decisionTreeTabClicked)
}, 5000)

export const updateProductsRows = (productId: string, productsRows: Partial<ProductRowType>[] = []) =>
  updateContextState((state) => {
    state.productsRows[productId] = uniqueArrayById([...productsRows] as any[])
  })

export const updateProductsToDisplay = (productsToDisplay: Product[]) =>
  updateContextState((state) => {
    state.productsToDisplay = productsToDisplay
  })

export const updateTab = (currentTab: string) => {
  isAnalyticsEnabled() && debouncedClickTracking(currentTab)
  return updateContextState((state) => {
    state.currentTab = currentTab
  })
}

export const adjustTotalWhenProductRemoved = (item: Product) =>
  updateContextState((state) => {
    state.selectedAddons = state.selectedAddons?.filter((addon) => addon.parentId !== item.id)
    if (state.phasesConfig && state.phasesConfig.itemGroups) {
      const filteredGroups = state.phasesConfig?.itemGroups
        .map((itemGroup) => {
          const newItemGroup = { ...itemGroup }
          newItemGroup.itemRows = itemGroup.itemRows.filter((row) => row.item.parentId !== item.id)

          return newItemGroup
        })
        .filter((itemGroup) => itemGroup.itemRows.length > 0)

      if (filteredGroups.length) {
        state.phasesConfig = {
          itemGroups: filteredGroups,
        }
      }
    }
  })

export const addPhaseConfigIfProductHasPhase = (item: Product) => {
  updateContextState((state) => {
    if (state.phasesConfig && item.phase) {
      const addonParams: {
        id: string
        parentId: string
        parentCartId: string
        sourceRowId: string
        sourceOptionId?: string
      }[] = []

      const allAddonItems = state.addOnItems
      const existingSelectedAddons = state.selectedAddons

      item.rows.forEach((row) => {
        if (!row.options?.usesAddOnItems) return

        item.values[row.id]?.forEach?.((selection: string) => {
          const parsedSelection = safeJsonParse<ParsedRowOptionItemValue | null>(selection, null)

          const parsedValue = safeJsonParse<{ items: { itemId: string; requireConfiguration: boolean }[] } | null>(
            parsedSelection?.value,
            null,
          )

          parsedValue?.items?.forEach((conf) => {
            if (!conf.requireConfiguration) return

            addonParams.push({
              id: conf.itemId,
              parentId: item.id,
              parentCartId: item.cartId,
              sourceRowId: row.id,
              sourceOptionId: parsedSelection?.id,
            })
          })
        })
      })

      const existingSelectedAddonsByKey = existingSelectedAddons?.reduce(
        (memo: { [key: string]: PhaseRowItemType }, addon) => {
          const key = getCompositeAddonKey(addon)
          memo[key] = memo[key] || addon
          return memo
        },
        {},
      )
      const selectedAddons = addonParams.map((params) => {
        const existingAddon = existingSelectedAddonsByKey?.[getCompositeAddonKey(params)]

        if (existingAddon) return existingAddon

        const addon = allAddonItems?.find((addon) => {
          return addon.id === params.id
        })

        return {
          ...addon,
          ...params,
          addonId: getCartId(),
        }
      })

      const newRows: PhaseItemRowType[] = selectedAddons.map((item) => {
        const itemRow = {
          item,
          id: item.addonId,
        } as any
        if (!item.phase) return itemRow

        const { phasesBreakdown } = item
        return {
          ...itemRow,
          isExpanded: false,
          allPhases: uniq(phasesBreakdown?.phases),
          allRoles: uniqBy(phasesBreakdown?.roles, 'label'),
          phasesBreakdown,
        }
      })

      const newItemGroups = [...state.phasesConfig.itemGroups]

      if (newItemGroups[0]) {
        newItemGroups[0].itemRows = [...newItemGroups[0].itemRows, ...newRows]
      }

      state.phasesConfig = { itemGroups: newItemGroups }
    }
  })
}

export const adjustPhasesConfigOnItemChange = (item: Product) => {
  updateContextState((state) => {
    if (state.phasesConfig && item.phase) {
      const addonParams: {
        id: string
        parentId: string
        parentCartId: string
        sourceRowId: string
        sourceOptionId?: string
      }[] = []

      const allAddonItems = state.addOnItems
      const existingSelectedAddons = state.selectedAddons

      state.cart.forEach((item) => {
        item.rows.forEach((row) => {
          if (!row.options?.usesAddOnItems) return

          item.values[row.id]?.forEach?.((selection: string) => {
            const parsedSelection = safeJsonParse<ParsedRowOptionItemValue | null>(selection, null)
            const parsedValue = safeJsonParse<{ items: { itemId: string; requireConfiguration: boolean }[] } | null>(
              parsedSelection?.value,
              null,
            )

            parsedValue?.items?.forEach((conf: any) => {
              if (!conf.requireConfiguration) return

              addonParams.push({
                id: conf.itemId,
                parentId: item.id,
                parentCartId: item.cartId,
                sourceRowId: row.id,
                sourceOptionId: parsedSelection?.id,
              })
            })
          })
        })
      })

      const existingSelectedAddonsByKey = existingSelectedAddons?.reduce(
        (memo: { [key: string]: PhaseRowItemType }, addon) => {
          const key = getCompositeAddonKey(addon)
          memo[key] = memo[key] || addon
          return memo
        },
        {},
      )
      const selectedAddons = addonParams.map((params) => {
        const existingAddon = existingSelectedAddonsByKey?.[getCompositeAddonKey(params)]
        if (existingAddon) return existingAddon

        const addon = allAddonItems?.find((addon) => {
          return addon.id === params.id
        })

        return {
          ...(addon || {}),
          ...params,
          addonId: getCartId(),
        }
      })

      const newRows: PhaseItemRowType[] = selectedAddons.map((item) => {
        const itemRow: any = {
          item,
          id: item.addonId,
        }

        if (!item.phase) return itemRow

        const { phasesBreakdown } = item
        return {
          ...itemRow,
          isExpanded: false,
          allPhases: uniq(phasesBreakdown?.phases),
          allRoles: uniqBy(phasesBreakdown?.roles, 'label'),
          phasesBreakdown,
        }
      })

      const rowsToAdd: PhaseItemRowType[] = []

      newRows.forEach((row) => {
        const shouldAddRow = state.phasesConfig.itemGroups.every((group) => {
          return group.itemRows.every((existingRow) => row.id !== existingRow.id)
        })

        if (shouldAddRow) {
          rowsToAdd.push(row)
        }
      })

      const newGroups = state.phasesConfig.itemGroups.map((group) => {
        const copyGroup = { ...group }

        copyGroup.itemRows = copyGroup.itemRows.filter((row) => newRows.some((newRow) => newRow.id === row.id))

        return copyGroup
      })

      if (newGroups[0]) {
        newGroups[0].itemRows = [...newGroups[0].itemRows, ...rowsToAdd]
      }

      state.phasesConfig = { itemGroups: newGroups }
    }
  })
}
