import React, { useEffect } from 'react'
import {
  DocumentTemplateType,
  PhaseItemRowType,
  PhaseRowItemType,
  CartItem as CartItemType,
} from '@tools/wr-catalog-base'
import {
  Button,
  Divider,
  DropDownInput,
  formatCurrency,
  phasesModule,
  Link,
  updateContextState,
  context,
} from '@tools/wr-catalog-base'
import { getAddonsFromSelections } from '@routes/AddonsView/utils'
import getDiscount from 'utils/product/getAddonDiscount'
import getProductPrice from 'utils/product/getProductPrice'

import {
  CartBody,
  CartContainer,
  CartFooter,
  CartHeading,
  DropDownInputWrapper,
  CartHours,
  CartPlaceholder,
} from './style'
import { useCartCalculations } from '@hooks/useCartCalculations'
import { CartItem, ItemSpecType } from './CartItem'
import { getAllowedTemplates, renderRoleHoursHtml } from './utils'

const Cart = ({
  cart,
  removeProductFromCart,
  getRoutePath,
  templates,
  templateSelected,
  defaultTemplateId,
  addOnItems,
  selectedAddons,
  selectedRegion,
  nextUrl,
}: {
  cart: CartItemType[]
  removeProductFromCart: typeof context.actions.removeProductFromCart
  getRoutePath: typeof context.getRoutePath
  templates: DocumentTemplateType[]
  templateSelected: SelectedTemplateType
  defaultTemplateId: string
  addOnItems: PhaseRowItemType[]
  selectedAddons: PhaseRowItemType[]
  selectedRegion: string
  nextUrl: string
}) => {
  const documentTemplates = getAllowedTemplates(cart)

  const phaseCostOptions = {
    rateOverrideKey: selectedRegion,
    getDiscount,
  }
  const selectedAddonsPlus = getAddonsFromSelections(cart, addOnItems, selectedAddons)

  const { totalCartValueWithAddons, phasesConfig, companySettings } = context.state

  const calcs = useCartCalculations(cart, selectedAddonsPlus, phasesConfig?.itemGroups)

  const { totalHours, totalWeeks } = calcs

  const itemSpecs: ItemSpecType[] = cart.map((item) => {
    const addons = selectedAddonsPlus.filter((addon) => addon.parentCartId === item.cartId)

    const addonPrices = addons.map((addon: PhaseRowItemType) => {
      const detailedCost = phasesModule.utils.calculateItemRowCost(
        { item: addon } as PhaseItemRowType,
        phaseCostOptions,
      )
      return detailedCost
    })

    return {
      id: item.id,
      addons,
      addonPrices,
      totalPrice: phasesModule.utils.sumCosts(...addonPrices),
    }
  })

  const cartItemsCount = () => {
    const mainItemsCount = itemSpecs.length
    const addonsCount = itemSpecs.reduce((acc, cartItem) => (acc += cartItem.addons?.length || 0), 0)

    return mainItemsCount + addonsCount
  }

  const detailedPhaseTotal = phasesModule.utils.sumCosts(...itemSpecs.map((sp) => sp.totalPrice))

  const canAccessPhases = Boolean(companySettings?.canAccessPhases)
  const normalCartTotal = cart.reduce((acc, v) => acc + getProductPrice(v, true, true), 0)
  const phasesCartTotal = (totalCartValueWithAddons?.discountedCost || detailedPhaseTotal.discountedCost) / 100
  const hasAnyPhaseItem = cart.some((item) => !!item.phase) && canAccessPhases
  const total = normalCartTotal + phasesCartTotal

  useEffect(() => {
    updateContextState((state) => {
      state.cartCalculations.totalHours = totalHours
      state.cartCalculations.totalWeeks = totalWeeks
    })
  }, [totalHours])

  return (
    <CartContainer>
      <CartHeading>
        <div>
          <p className="cart-heading">Checkout</p>
          <p className="cart-muted">
            {cartItemsCount()} item{cartItemsCount() === 1 ? '' : 's'}
          </p>
        </div>
        <div>
          <h1 className="cart-heading cart-active" data-testid="cart-total-price">
            {formatCurrency(total)}
          </h1>
          <p className="cart-muted">Total Cost</p>
        </div>
      </CartHeading>
      <CartHours>
        {renderRoleHoursHtml(calcs)}
        {totalHours ? <p className="cart-muted">Total Hours: {totalHours}</p> : null}
        {totalWeeks ? <p className="cart-muted">Total Weeks: {totalWeeks}</p> : null}
      </CartHours>
      <Divider />
      <CartBody>
        {cart.length ? (
          <>
            <DropDownInputWrapper>
              <DropDownInput
                label="Output Document"
                placeholder="Choose SOW Template"
                onChange={(value: SelectedTemplateType) => {
                  updateContextState((state) => {
                    state.templateSelected = value
                  })
                }}
                value={templateSelected}
                styles={{
                  indicatorSeparator: () => null,
                }}
                options={documentTemplates.sort((template) => {
                  return template.value === defaultTemplateId ? -1 : 0
                })}
                fullWidth
              />
            </DropDownInputWrapper>
            {cart.map((item) => (
              <CartItem
                key={`CartProduct__${item.id}--${item.cartId}}`}
                item={item}
                itemSpecs={itemSpecs}
                selectedAddonsPlus={selectedAddonsPlus}
                removeProductFromCart={removeProductFromCart}
                selectedRegion={selectedRegion}
              />
            ))}
          </>
        ) : (
          <CartPlaceholder />
        )}
      </CartBody>
      <Divider />
      <CartFooter data-disabled={!cart.length}>
        <Link to={nextUrl || getRoutePath(hasAnyPhaseItem ? 'addOns' : 'document-edit')}>
          <Button data-testid="cart-continue" disabled={!documentTemplates?.length}>
            Continue
          </Button>
        </Link>
      </CartFooter>
    </CartContainer>
  )
}

export default Cart
