import { CartItem, context, DocumentBuilderExportOptions } from '@tools/wr-catalog-base'

export const getBuilderContext = () => {
  const { cart, cartCalculations, ganttChartData } = context.state

  const { totalHours, totalWeeks, totalPrice } = cartCalculations || {}

  const ctx = {
    cart,
    totalWeeks,
    totalHours,
    totalPrice,
    ganttChartData,
    html: '',
  }

  return ctx as DocumentBuilderContextType
}

interface DocumentBuilderContextProps {
  cart: CartItem[]
  totalWeeks: number
  totalHours: number
  totalPrice: number
  ganttChartData: GanttChartDataType
}

export interface DocumentBuilderContextType extends DocumentBuilderContextProps {
  cart: CartItem[]
  totalWeeks: number
  totalHours: number
  totalPrice: number
  ganttChartData: GanttChartDataType
  html: string
  exportOptions: DocumentBuilderExportOptions
  fromDecisionTree?: boolean
  formValues?: {
    name: string
    company: string
    email: string
    date: string
  }
}
