import { css, extendTheme, standardTheme } from '@tools/wr-catalog-base'

import logo from '../assets/logo.png'
import heroBackground from '../assets/hero-background.png'

export const theme = extendTheme({
  palette: {
    primary: '#5CC1A8',
    lightBlue: '#5CC1A8',
  },
  brand: {
    name: 'Build Your Own Price Quote',
    tagline: '',
    logo,
  },
  components: {
    content: css`
      ${standardTheme.components.content};
      margin-top: 0;
    `,
    hero: {
      backgroundImage: `url(${heroBackground})`,
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '265px',
      padding: '55px 0',
      top: '50%',

      brandName: {
        brandNameHeight: '52px',
        brandNameFontWeight: '500',
      },

      tagline: {
        taglineHeight: '13px',
        taglineLineHeight: '1.5',
        taglineMaxWidth: '1000px',
        taglineMargin: '0 auto',
      },
    },
    card: {
      borderColor: '#ddd',
      borderColorHover: '#ccc',
      borderRadius: '0',
      selectedIconColor: '#888',
    },
    dropdown: {
      borderRadius: '5px',
    },
    navbar: {
      height: '88px',
      position: 'fixed',
    },
    decisionTree: {
      displaySelectedIcon: true,
      answers: {
        backgroundColor: '#e0e0e0',
        border: '1px solid #d6d6d6',
        color: '#333',
        selectedAnswer: '#e0e0e0',
        selectedAnswerBorder: '1px solid #afafaf',
      },
      footer: {
        buttonBorderRadius: '5px',
      },
    },
    modal: {
      borderRadius: '5px',
      confirmation: {
        buttons: {
          borderRadius: '0px',
          padding: '0.2rem 1rem',
        },
      },
    },
    productList: {
      centered: { start: 'xs' },
    },
    backButton: {
      ...standardTheme.components.backButton,
      container: css`
        color: ${({ theme }) => theme.palette.primary};
        font-style: normal;
        cursor: pointer;
        white-space: nowrap;
      `,
    },

    dropdownInput: {
      styles: {
        option: (state: any) => ({ ...state, 'font-size': '13px' }),
      },
    },
    native: {
      select: css<{ fullWidth?: boolean }>`
        position: relative;
        width: ${({ theme, fullWidth = false }) => (fullWidth ? '100%;' : theme.components.form?.width)};
        display: inline-block;
        border: 1px solid ${({ theme }) => theme.palette.muted};
        padding: 0.375rem 2rem 0.375rem 0.5rem;
        font-size: 1em;
        line-height: calc(1em - 2px);
        appearance: none;
        margin-top: 0.5rem;
        cursor: pointer;
        font-family: inherit;
        transition: 0.2s ease-in-out box-shadow, 0.2s ease-in-out border;

        background-color: #ffffff;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwb2x5Z29uIHBvaW50cz0iNDcuMjUsMTUgNDUuMTY0LDEyLjkxNCAyNSwzMy4wNzggNC44MzYsMTIuOTE0IDIuNzUsMTUgMjUsMzcuMjUgIi8+PC9zdmc+'),
          linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;

        &::ms-expand {
          display: none;
        }

        & > option {
          font-weight: normal;
        }

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          border-color: rgba(0, 123, 255, 0.25);
        }

        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 ${({ theme }) => theme.palette.primaryText};
        }

        & > option:focus {
          outline: 0;
          border: none;
        }
      `,
      input: css<{ fullWidth?: boolean; large?: boolean }>`
        border: 1px solid ${({ theme }) => theme.palette.muted};
        border-radius: 0;
        padding: ${({ large }) => (large ? '0.64rem' : '0.375rem')} 0.75rem;
        color: ${({ theme }) => theme.palette.primaryText};
        line-height: 1em;
        font-size: 1em;
        transition: 0.2s ease-in-out box-shadow, 0.2s ease-in-out border;
        font-family: inherit;

        max-width: ${({ theme }) => theme.components.form?.width};
        ${({ fullWidth }) => fullWidth && 'max-width: 100%;'}
        width: 100%;

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          border-color: rgba(0, 123, 255, 0.25);
        }
      `,
      textArea: css<{ fullWidth?: boolean; large?: boolean; noResize?: boolean }>`
        border: 1px solid ${({ theme }) => theme.palette.muted};
        padding: ${({ large }) => (large ? '0.64rem' : '0.375rem')} 0.75rem;
        resize: ${({ noResize }) => (noResize ? 'none' : 'vertical')};
        color: ${({ theme }) => theme.palette.primaryText};
        line-height: 1em;
        height: 100px;
        font-size: 1em;
        width: 100%;
        transition: 0.2s ease-in-out box-shadow, 0.2s ease-in-out border;

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          border-color: rgba(0, 123, 255, 0.25);
        }
      `,
      button: css`
        ${standardTheme.components.native.button};
        &[disabled] {
          color: rgba(192, 192, 189, 0.86);
        }
        &:hover {
          color: #fff;
        }
      `,
    },
    label: {
      fontSize: '0.91rem',
    },
  } as any,
})
