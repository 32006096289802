import { getProductRow, Product, ProductRowType, RowVariants } from '@tools/wr-catalog-base'
import { getConsultingProductPrice } from './getConsultingProductPrice'
import { getProductDiscount } from './getProductDiscount'
import { getTamProductPrice } from './getTamProductPrice'

export default function getProductPrice(product: Product, withDiscount?: boolean, withMarkup?: Boolean) {
  const formValues = product.values
  const productControl = getProductRow(product, RowVariants.RowType, 'component:pricing')
    ?.control as any as ProductPriceControl
  const discount = getProductDiscount(product)
  const markupRow = getProductRow(product, RowVariants.Tag, 'totalMarkup')
  const saasFee = getProductRow(product, RowVariants.Tag, 'saasFee')

  let basePrice = productControl?.schedule?.cost ? productControl.schedule.cost / 100 || 0 : 0
  let price = 0
  let markup = 0

  // util functions
  const getPricingConstantByName = (name: string) => {
    return productControl?.constants?.filter((constant) => constant.name === name)[0]
  }

  const checkIfNotNumericField = (field?: ProductRowType | null) => {
    return field && field.options ? field.options.inputType !== 'numeric' : true
  }

  // conditions to make calculations by different product type
  if (formValues) {
    if (saasFee) {
      basePrice = Number(formValues[saasFee.id]) || 0
    }

    const ctx: GetProductPriceContext = {
      product,
      formValues,
      getPricingConstantByName,
      checkIfNotNumericField,
      basePrice,
      discount,
    }

    // Handle TAM product pricing
    if (productControl?.constants?.length) {
      const tamPrice = getTamProductPrice(ctx)

      if (tamPrice) price = tamPrice
    } else {
      // Handle Consulting product pricing
      price = getConsultingProductPrice(ctx)
    }

    markup = Number(formValues[markupRow?.id as string]) || 0

    if (withDiscount) {
      price = price - (price * discount) / 100

      if (withMarkup) {
        price += (price * Math.floor(markup)) / 100
      }

      return price
    } else {
      return price
    }
  } else {
    return basePrice
  }
}

export type ProductPriceControl =
  | {
      constants?: PriceControlConstant[]
      schedule?: PriceControlSchedule
    }
  | undefined
