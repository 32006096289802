import React from 'react'
import {
  PhaseAllocations,
  styled,
  context,
  PhasesRenderHeaderProps,
  RouteComponentProps,
  isServiceLevelDiscountingEnabled,
} from '@tools/wr-catalog-base'
import getAddonDiscount from 'utils/product/getAddonDiscount'
import { useDiscounting } from '@hooks/useDiscounting'

const Container = styled.div`
  margin-top: 30px;
`

const DiscountInput = styled.input.attrs({ type: 'text' })`
  width: 5ch;
  text-align: right;
`

const DiscountInputAmount = styled.input.attrs({ type: 'text' })`
  width: 8ch;
  text-align: left;
`

const numberRegex = /[0-9]/

const RenderAdditionalControls = (options: PhasesRenderHeaderProps) => {
  const { itemRow } = options
  const {
    discountAmountInput,
    discountPercentInput,
    setDiscountAmountInput,
    setDiscountPercentInput,
    updateDiscountAmount,
    updateDiscountPercent,
  } = useDiscounting(itemRow)

  return (
    <>
      {'Discount: '}
      <DiscountInput
        data-testid="phase-allocations-discount-percentage"
        value={discountPercentInput || '0'}
        onChange={(ev: any) => {
          updateDiscountPercent(+ev.target.value)
          setDiscountPercentInput(+ev.target.value)
        }}
      />
      % {' or $ '}
      <DiscountInputAmount
        data-testid="phase-allocations-discount-amount"
        value={discountAmountInput || ''}
        onChange={(ev: any) => {
          const input: string = ev.target.value

          // some input validation to allow for decimal values
          if (input && input.split('.').length < 3) {
            const lastChar = input.slice(-1)
            if (lastChar === '.') {
              setDiscountAmountInput(input)
              return
            } else if (numberRegex.test(lastChar)) {
              const discountCentAmount = +input * 100
              updateDiscountAmount(discountCentAmount)
              setDiscountAmountInput(input)
            }
          } else {
            updateDiscountAmount(0)
            setDiscountAmountInput(0)
          }
        }}
      />
    </>
  )
}

const renderItemRowHeader = (options: PhasesRenderHeaderProps) => {
  const isDiscountingEnabled = isServiceLevelDiscountingEnabled()
  return options.renderHeader({
    ...options,
    displayDiscountedPrice: isDiscountingEnabled,
    ...(isDiscountingEnabled ? { renderAdditionalControls: RenderAdditionalControls } : {}),
  })
}

const isAddPhaseButtonDisabled = () =>
  context.state.phasesConfig.itemGroups.length === context.state.selectedAddons.length

export default function AugmentedPhaseAllocations(props: RouteComponentProps) {
  return (
    <Container data-testid="phase-allocations-container">
      <PhaseAllocations
        {...props}
        renderItemRowHeader={renderItemRowHeader}
        getDiscount={getAddonDiscount}
        displayBidMargin
        disableAddPhaseButton={isAddPhaseButtonDisabled}
        displayDiscountedPrice={isServiceLevelDiscountingEnabled()}
      />
    </Container>
  )
}
