import { withContext } from '@tools/wr-catalog-base'

import AddonsView from './AddonsView'

export default withContext(AddonsView, {
  mapProps: (context) => ({
    addOnItems: context.state.addOnItems,
    selectedAddons: context.state.selectedAddons,
    cart: context.state.cart,
  }),
})
