import { styled } from '@tools/wr-catalog-base'

export const ItemTitle = styled.div`
  .product-detail-header {
    text-align: left;
  }

  .product-details-description-card {
    font-size: 0.95em;
  }
`
