import React, { useEffect, ReactNode } from 'react'
import { context, WorkflowLoadStateManager } from '@tools/wr-catalog-base'

type DataResolverProps = {
  children: ReactNode
}

function DataResolver({ children }: DataResolverProps) {
  useEffect(() => {
    context.state.workflow?.next()
  }, [])

  return <WorkflowLoadStateManager loadWhile="initialization">{children}</WorkflowLoadStateManager>
}

export default DataResolver
