import {
  safeJsonParse,
  updateContextState,
  phasesModule,
  Product,
  PhaseItemGroupType,
  isRowSkipped,
  ProductRowType,
  PhaseRowItemType,
} from '@tools/wr-catalog-base'
import _ from 'lodash'
import { WEEKS_PER_QUARTER } from '@routes/ProductDetails/consts'

const selectedQuartersRegex = /(\d+.)Quarter/gi
const selectedHoursRegex = /(\d+.)Hours/gi

const checkIfNotNumericField = (field: ProductRowType) => {
  if (!field || !field.options) return true
  return field.options.inputType !== 'numeric'
}

export const getProductHours = (
  item: Product,
  cart?: Product[],
  selectedAddons?: PhaseRowItemType[],
  itemGroups?: PhaseItemGroupType[] | null,
) => {
  let totalHours = 0
  let totalWeeks = 0
  let phaseHours = 0

  if (itemGroups) {
    phaseHours = _.sumBy(itemGroups?.map((itemGroup) => phasesModule.utils.calculateItemGroupHours(itemGroup)))
  } else {
    phaseHours = _.sumBy(selectedAddons?.map((addon) => phasesModule.utils.calculateItemRowHours(addon)))
  }

  const additionalHourFields = {}

  let quartersSelected: {
    amount?: number
    values?: {
      quarter: number
      hours?: number
    }
  } = {}

  let hoursSelected: {
    amount?: number
    values?: any
  } = {}
  let hasAnySelection = false

  Object.keys(item.values).forEach((key) => {
    const formValue = item.values[key]
    const row = item.rows.find((item) => item.id === key)
    const isArray = Array.isArray(formValue)
    const hasSelection = isArray || Object.keys(safeJsonParse(formValue)).length

    // don't add hours for skipped rows
    if (!row || isRowSkipped(item.values, row)) return

    if (formValue.length && hasSelection && row?.options?.tag !== 'custom_sow_text' && checkIfNotNumericField(row)) {
      hasAnySelection = true

      const formSelections = isArray ? formValue : [formValue]

      formSelections.forEach((formSelection) => {
        const parsedValue = JSON.parse(formSelection) || formSelection
        const values = JSON.parse(parsedValue.value)
        const quarters = selectedQuartersRegex.exec(parsedValue.label)
        const hours = selectedHoursRegex.exec(parsedValue.value)

        let multiplier = 1
        const note = row?.notes?.find((note) => note.label === parsedValue.label)

        if (note?.notesType === 'multiplier') {
          multiplier = Number(note?.noteValue || 0) > 1 ? Number(note.noteValue) : 1
        }

        if (quarters) {
          quartersSelected = {
            amount: +quarters[1],
            values: safeJsonParse(parsedValue.value, undefined),
          }
        }

        if (hours) {
          hoursSelected = {
            amount: +hours[1],
            values: safeJsonParse(parsedValue.value, undefined),
          }
        }

        const hoursVal = Number(values.Hours ?? values.hours)

        if (typeof hoursVal === 'number' && !Number.isNaN(hoursVal)) {
          totalHours += hoursVal * multiplier
        }

        if (values.hours) {
          Object.keys(values.hours).forEach((field) => {
            if (!field.includes('hours')) return

            const fieldName = 'total' + field.split('_')[1].toUpperCase() + 'Hours'
            Object.defineProperty(additionalHourFields, fieldName, {
              value: values.hours[field],
              enumerable: true,
              writable: true,
            })
          })

          if (Object.keys(additionalHourFields).length) {
            totalHours += (Object.values(additionalHourFields) as number[]).reduce((prev, curr) => prev + curr)
          }

          if (values.hours?.weeks) {
            totalWeeks += values.hours.weeks
          }
        }
      })

      if (hasAnySelection && !Object.keys(additionalHourFields).length) {
        if (Object.keys(quartersSelected).length && Object.keys(hoursSelected).length) {
          totalHours +=
            hoursSelected.values.filter((el: { quarter: number }) => el.quarter === quartersSelected.amount)[0].hours +
            phaseHours

          totalWeeks = parseInt(quartersSelected.amount as any) * WEEKS_PER_QUARTER + totalHours / 40
        } else {
          totalHours += phaseHours
          totalWeeks = totalHours / 40
        }
      }
    }
  })

  return {
    totalHours: +totalHours.toFixed(2),
    totalWeeks: +totalWeeks.toFixed(2),
    ...additionalHourFields,
  }
}
