import { context, getProductRow, Product, RowVariants, tagReplacer } from '@tools/wr-catalog-base'
import { CartArray, CatalogIdsEnum } from './addExhibitsToTable'

/**
 * @refactor all fromDecisionTree logic seems not used.
 */
export const addExhibits = (
  documentContainer: HTMLDivElement,
  fromDecisionTree?: boolean,
  formValues = {
    name: '',
    company: '',
    email: '',
    date: '',
  },
  totalWeeks?: number,
  totalHours?: number,
) => {
  const {
    state: { decisionTreeCart, cart: contextCart, catalogs },
  } = context
  const { company } = formValues
  const exhibitsContainer = documentContainer.querySelector<HTMLElement>('#exhibits')
  const cart = fromDecisionTree ? decisionTreeCart : contextCart
  const cartArray: CartArray = !fromDecisionTree
    ? {
        '636a6862-2123-4811-b930-8c02afd54fdf': [],
        '2e46d418-fa10-446d-801b-d305495d10d4': [],
        'c7699ac3-8d47-4806-9494-903d42b3be75': [],
      }
    : null
  const catalogItems = catalogs?.[0]?.contents?.items

  if (cartArray && cart.length) {
    ;(cart as Product[]).forEach?.((cartItem) => {
      catalogItems?.forEach?.((catalogItem) => {
        if (cartItem.id === catalogItem.id) {
          catalogItem.categories.forEach((category) => {
            if (category.id === 'c7699ac3-8d47-4806-9494-903d42b3be75') {
              cartArray['c7699ac3-8d47-4806-9494-903d42b3be75'].push(cartItem)
            }
          })
        }
      })
    })
  }

  const addNewExhibit = (item: Product, index: number) => {
    const exhibit = document.createElement('div')

    exhibit.setAttribute('id', `#exhibit-${item.id}`)

    const sowRow = getProductRow(item, RowVariants.Tag, 'sow')

    if (sowRow) {
      let newHtml = sowRow.control as string
      newHtml += '<Page Break>'

      newHtml = tagReplacer(newHtml, 'Exhibit X', `Exhibit ${String.fromCharCode(index + 65)}`)
      newHtml = tagReplacer(newHtml, 'item name', item.name)
      newHtml = tagReplacer(newHtml, 'Customer', company || '')
      newHtml = tagReplacer(newHtml, 'Hours', `${totalHours} Hours`)
      newHtml = tagReplacer(newHtml, 'Weeks', `${totalWeeks} Weeks`)
      newHtml = tagReplacer(
        newHtml,
        'Page Break',
        '<div class="pagebreak" clear="all" style="page-break-before: always"></div>',
      )

      exhibit.innerHTML = newHtml
      exhibitsContainer?.appendChild(exhibit)
    }
  }

  if (cart.length) {
    ;(cart as Product[]).forEach?.((cartItem, index) => {
      addNewExhibit(cartItem, index)
    })
  } else if (cartArray) {
    Object.keys(cartArray).forEach((key, index) => {
      cartArray[key as CatalogIdsEnum].forEach((item) => {
        addNewExhibit(item, index)
      })
    })
  }

  return documentContainer
}
