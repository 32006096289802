import React from 'react'
import Select from 'react-select'
import { styled, withContext, updateContextState, WrContextType } from '@tools/wr-catalog-base'

const StyledSelect = styled(Select)`
  width: 200px;
`

function RegionDropdown(props: { context: WrContextType }) {
  const {
    context: {
      state: { regions, selectedRegion },
    },
  } = props

  const options = regions?.map((reg) => ({
    value: reg,
    label: reg,
  }))

  const selectedOption = options?.find((opt) => opt.value === selectedRegion)

  return (
    <div data-testid="region-dropdown">
      <StyledSelect
        defaultMenuIsOpen={!!process.env.REACT_APP_WR_TEST}
        placeholder="Select a region"
        isClearable
        value={selectedOption}
        options={options}
        classNamePrefix="region-dropdown"
        className="region-dropdown-test"
        // @refactor
        onChange={(option?: any) => {
          updateContextState((state) => {
            state.selectedRegion = option?.value as string
          })
        }}
      />
    </div>
  )
}

export default withContext(RegionDropdown)
