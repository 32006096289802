import React from 'react'
import { LoginForm, FullHeightRow, SpacedCol, FullHeightGrid } from '@tools/wr-catalog-base'

const LoginView = () => {
  return (
    <div data-testid="login-test">
      <FullHeightGrid fluid>
        <FullHeightRow middle="xs" center="xs">
          <SpacedCol lg={3} md={6} sm={9} xs={12}>
            <LoginForm />
          </SpacedCol>
        </FullHeightRow>
      </FullHeightGrid>
    </div>
  )
}

export default LoginView
