import { context, calculateItemRowCost, PhaseItemRowType, updateContextState } from '@tools/wr-catalog-base'
import { useState, useEffect, useRef } from 'react'

export const useDiscounting = (itemRow?: PhaseItemRowType) => {
  const [discountAmountInput, setDiscountAmountInput] = useState((itemRow?.item?.discountAmount ?? 0) / 100 || '0')
  const [discountPercentInput, setDiscountPercentInput] = useState((itemRow?.item?.discountPercent ?? 0) || '0')
  const selectedRegion = context.state.selectedRegion
  const firstRender = useRef(true)

  const handlePercentSync = () => {
    if (itemRow) {
      const discountPercent =
        ((+discountAmountInput * 100) / calculateItemRowCost(itemRow, { rateOverrideKey: selectedRegion }).fullCost) *
        100
      itemRow.item.discountPercent = Number(discountPercent.toFixed(2))
    }
  }

  const handleAmountSync = () => {
    if (itemRow) {
      const discountedAmount =
        (calculateItemRowCost(itemRow, { rateOverrideKey: selectedRegion }).fullCost *
          +(itemRow.item?.discountPercent || 0)) /
        100
      itemRow.item.discountAmount = +discountedAmount
      setDiscountAmountInput((+discountedAmount / 100).toFixed(2))
    }
  }

  const updateDiscountAmount = (discountCentAmount: number) => {
    updateContextState(() => {
      if (itemRow) {
        itemRow.item.discountAmount = discountCentAmount
      }
    })
  }

  const updateDiscountPercent = (discountPercent: number) => {
    updateContextState(() => {
      if (itemRow) {
        itemRow.item.discountPercent = discountPercent
      }
    })
  }

  useEffect(() => {
    if (!firstRender.current) handlePercentSync()
  }, [discountAmountInput])

  useEffect(() => {
    if (!firstRender.current) handleAmountSync()
  }, [discountPercentInput])

  useEffect(() => {
    handlePercentSync()
  }, [selectedRegion])

  useEffect(() => {
    firstRender.current = false
  }, [])

  return {
    discountAmountInput, // To allow for input values like "12."
    discountPercentInput: itemRow?.item.discountPercent ?? 0, // decimal values are not allowed here
    setDiscountAmountInput,
    setDiscountPercentInput,
    updateDiscountAmount,
    updateDiscountPercent,
  }
}
