import { connectRequest, context, modules, withContext } from '@tools/wr-catalog-base'

import ProductDetails from './ProductDetails'

export default connectRequest(({}) => {
  return {
    catalogDataRequest: modules.product.requests.fetchProductsByCatalogId(context.constants.client.catalogIds[0]),
  }
})(
  withContext(ProductDetails, {
    mapProps: (context) => ({
      catalogs: context.state.catalogs,
      cart: context.state.cart,
      actions: context.actions,
      getProductPrice: context.utils.product.getPrice,
    }),
  }),
)
