import React, { useState, Fragment } from 'react'
import uniq from 'lodash/uniq'
import { getChartSpecs, PhaseItemGroupType } from '@tools/wr-catalog-base'

const BASE_CONFIG = {
  barHeight: 20,
  width: 600,
  height: 512,
  leftPaneWidth: 200,
  betweenGroupsGutter: 20,
  betweenRowsGutter: 10,
  mainContentTop: 40,
}

type Override = {
  height: number
  leftPaneWidth: number
}

const getChartConfig = (overrides: Override | null) => {
  const config = {
    ...BASE_CONFIG,
    ...overrides,
  }

  // `-1` to have the last vertical line fully visible
  const rightPaneWidth = config.width - config.leftPaneWidth - 1
  const headerHeight = 1.5 * config.barHeight

  return { ...config, rightPaneWidth, headerHeight }
}

const fixDimensions = (dom: SVGSVGElement, setOverrides: React.Dispatch<React.SetStateAction<Override | null>>) => {
  let maxY = 0
  Array.from(dom.querySelectorAll('rect')).forEach((rect) => {
    maxY = Math.max(maxY, (rect.y?.baseVal?.value || 0) + (rect.height?.baseVal?.value || 0))
  })

  let maxXLeftPane = 0
  Array.from(dom.querySelectorAll('text')).forEach((text) => {
    const { x, width } = text.getBBox?.() || { x: 0, width: 0 }
    maxXLeftPane = Math.max(maxXLeftPane, x + width)
  })
  setOverrides({
    height: maxY + 10,
    leftPaneWidth: Math.min(150, maxXLeftPane + 5),
  })
}

/**
 *
 * @param {Object} props
 * @param {Array<import('../GanttView').ItemGroup>} props.itemGroups
 * @returns
 */
export default function SvgGantt(props: {
  svgRef: React.MutableRefObject<SVGSVGElement>
  itemGroups: PhaseItemGroupType[]
}) {
  const { itemGroups, svgRef } = props

  const { totalWeeks, barGroups } = getChartSpecs(itemGroups)

  const [overrides, setOverrides] = useState<Override | null>(null)
  const config = getChartConfig(overrides)
  const getWeekX = (week: number, totalWeeks: number) => {
    const x = config.leftPaneWidth + (config.rightPaneWidth * week) / totalWeeks
    return x
  }
  let currentTop = config.mainContentTop - config.headerHeight
  const renderWithCurrentTop = (height: number, node: React.ReactNode) => {
    currentTop += height
    return node
  }

  return (
    // `display: none` makes the svg elements have no size
    <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
      <svg
        ref={(dom) => {
          if (!dom) return

          svgRef.current = dom
          if (!overrides) {
            fixDimensions(dom, setOverrides)
          }
        }}
        viewBox={`0 0 ${config.width} ${config.height}`}
        style={{
          width: config.width,
          height: config.height,
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {itemGroups.map((itemGroup, gi) => {
          currentTop += config.betweenGroupsGutter
          return (
            <Fragment key={gi}>
              {renderWithCurrentTop(
                1.5 * config.barHeight,
                <text
                  key={`${gi}:text`}
                  x="0"
                  y={currentTop + config.barHeight / 2}
                  dominantBaseline="central"
                  style={{ fontSize: '1.5em' }}
                  children={`Phase ${gi + 1}`}
                />,
              )}
              {itemGroup.itemRows.map((itemRow, ri) => {
                currentTop += config.betweenRowsGutter
                const pathMatch = ([_gi, _ri]: number[]) => _gi === gi && _ri === ri

                // @refactor
                const { startWeek, weekCounts, color } = barGroups.find((bg) => pathMatch(bg.indexPath)) ?? {
                  startWeek: 0,
                  weekCounts: [],
                  color: '',
                }

                const phases = uniq(itemRow.phasesBreakdown.phases)
                let currentWeek = startWeek
                return (
                  <Fragment key={ri}>
                    {renderWithCurrentTop(
                      1.5 * config.barHeight,
                      <text
                        key={`${gi}:${ri}:text`}
                        x="0"
                        y={currentTop + config.barHeight / 2}
                        dominantBaseline="central"
                        style={{ fontWeight: 'bold' }}
                        children={itemRow.item.name}
                      />,
                    )}
                    {phases.map((phase, pi) => {
                      const weekCount = weekCounts[pi]
                      const x = getWeekX(currentWeek, totalWeeks)
                      const xw = getWeekX(currentWeek + weekCount, totalWeeks)
                      currentWeek += weekCount

                      return renderWithCurrentTop(
                        config.barHeight,
                        <Fragment key={pi}>
                          <rect
                            key={`${gi}:${ri}:${pi}:rect`}
                            x={x}
                            y={currentTop}
                            height={config.barHeight}
                            width={xw - x}
                            fill={color}
                          />
                          <text
                            key={`${gi}:${ri}:${pi}:text`}
                            x="0"
                            y={currentTop + config.barHeight / 2}
                            dominantBaseline="central"
                            children={phase}
                          />
                        </Fragment>,
                      )
                    })}
                  </Fragment>
                )
              })}
            </Fragment>
          )
        })}
        {Array(totalWeeks + 1)
          .fill(0)
          .map((_, i) => {
            const x = Math.round(getWeekX(i, totalWeeks))
            let label = null
            if (i) {
              const prevX = getWeekX(i - 1, totalWeeks)
              label = <text x={(x + prevX) / 2} y="5" textAnchor="middle" dominantBaseline="hanging" children={i} />
            } else {
              label = <text x={x - 10} y="5" textAnchor="end" dominantBaseline="hanging" children="Week" />
            }
            return (
              <Fragment key={i}>
                {label}
                <line x1={x} y1="0" x2={x} y2="100%" stroke="black" strokeDasharray="2 2" />
              </Fragment>
            )
          })}
      </svg>
    </div>
  )
}
