import React from 'react'
import {
  BackButton,
  Col,
  ProductDetail,
  Row,
  updateContextState,
  styled,
  CartItem,
  FormikValues,
  CatalogsType,
  RouteComponentProps,
} from '@tools/wr-catalog-base'
import Cart from '@components/Cart'

import { ItemTitle } from './style'
import { addPhaseConfigIfProductHasPhase, adjustPhasesConfigOnItemChange } from '@store/actions'
import { attachNoteToRows } from 'utils/product/attachNoteToRows'

const Container = styled.div.attrs({
  className: 'container',
})`
  font-size: 14px;
  margin-top: 30px;
`

interface ProductDetailsProps extends RouteComponentProps<{ productId: string; bundleId: string }> {
  catalogs: CatalogsType[]
  cart: CartItem[]
}

const ProductDetails = ({ match, cart }: ProductDetailsProps) => {
  const productId = match.params.productId || match.params.bundleId
  const existingProduct = cart.find((v) => v.id === productId)

  const onChangeHandle = (values: FormikValues) => {
    updateContextState((state) => {
      const cartItem = state.cart.find((p) => p.id === productId)
      if (cartItem) {
        attachNoteToRows(values, cartItem)
        adjustPhasesConfigOnItemChange(cartItem)
      }
    })
  }

  return (
    <>
      <Container>
        <Row middle="xs">
          <Col xs={12}>
            <BackButton />
          </Col>
        </Row>
        <br />
        <Row middle="xs" center="xs">
          <Col lg={12} md={12} sm={12} xs={12}>
            <ItemTitle>
              <ProductDetail
                initialValues={existingProduct?.values}
                productId={existingProduct?.id || productId}
                onChange={onChangeHandle}
                enableValidation={true}
                asPdf={true}
                Cart={Cart}
                showCartAlways={true}
                showBackButton={false}
                onCartAdd={addPhaseConfigIfProductHasPhase}
              >
                {(renderedParts /* , options */) => (
                  <>
                    {renderedParts.feedback}
                    {renderedParts.backButton}
                    <Row data-testid="content-container">
                      <Col xs={12} sm={7}>
                        {renderedParts.mainSection}
                      </Col>
                      <Col xs={12} sm={5}>
                        {renderedParts.cartSection}
                      </Col>
                    </Row>
                    {renderedParts.noProducts}
                  </>
                )}
              </ProductDetail>
            </ItemTitle>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductDetails
