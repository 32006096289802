import { context, formatCurrency, insertAfter, Product, tagReplacer } from '@tools/wr-catalog-base'
import { calculateFinalPrice } from '../product/calculateFinalPrice'
import getProductPrice from '../product/getProductPrice'

export type CatalogIdsEnum =
  | '636a6862-2123-4811-b930-8c02afd54fdf'
  | '2e46d418-fa10-446d-801b-d305495d10d4'
  | 'c7699ac3-8d47-4806-9494-903d42b3be75'

export type CartArray = null | { [key in CatalogIdsEnum]: Product[] }

/**
 * @refactor all fromDecisionTree logic seems not used.
 */
export const addExhibitsToTable = (documentContainer: HTMLDivElement, fromDecisionTree?: boolean) => {
  const {
    state: { decisionTreeCart, cart: contextCart, catalogs },
  } = context
  const table = documentContainer.querySelector<HTMLElement>('#exhibits-table')

  if (table) {
    table.querySelectorAll<HTMLElement>('tr td').forEach((row) => {
      row.style.borderColor = '#000'
    })
  }

  if (!table) {
    const cart = fromDecisionTree ? decisionTreeCart : contextCart
    const cartArray: CartArray = !fromDecisionTree
      ? {
          '636a6862-2123-4811-b930-8c02afd54fdf': [] as Product[],
          '2e46d418-fa10-446d-801b-d305495d10d4': [] as Product[],
          'c7699ac3-8d47-4806-9494-903d42b3be75': [] as Product[],
        }
      : null

    let totalPrice = 0
    let totalDiscount = 0
    let totalMarkup = 0
    let totalPriceWithMarkup = 0
    let totalDiscountPrice = 0

    const calculateDiscount = (item: Product) => {
      const priceWithoutDiscount = getProductPrice(item, false)
      const priceWithDiscount = getProductPrice(item, true)
      const priceWithMarkup = getProductPrice(item, true, true)

      totalPrice = totalPrice + priceWithoutDiscount
      totalDiscount = totalDiscount + (priceWithoutDiscount - priceWithDiscount)
      totalDiscountPrice = totalDiscountPrice + priceWithDiscount
      totalMarkup = totalMarkup + priceWithMarkup - totalDiscountPrice
      totalPriceWithMarkup = totalPriceWithMarkup + priceWithMarkup
    }

    if (cart.length) {
      ;(cart as Product[]).forEach?.((cartItem) => {
        calculateDiscount(cartItem)
      })
    } else if (cartArray) {
      Object.keys(cartArray).forEach((key) => {
        cartArray?.[key as CatalogIdsEnum]?.forEach((item) => {
          calculateDiscount(item)
        })
      })
    }

    let newHtml = documentContainer.innerHTML.toString()
    newHtml = tagReplacer(newHtml, 'total discount', formatCurrency(totalDiscount).toLocaleString())
    newHtml = tagReplacer(newHtml, 'total price', formatCurrency(totalPrice).toLocaleString())
    newHtml = tagReplacer(newHtml, 'total discount price', formatCurrency(totalDiscountPrice).toLocaleString())
    newHtml = tagReplacer(newHtml, 'total markup', formatCurrency(totalMarkup).toLocaleString())
    newHtml = tagReplacer(newHtml, 'total markup price', formatCurrency(totalPriceWithMarkup).toLocaleString())
    documentContainer.innerHTML = newHtml

    return documentContainer
  }

  const tableHeader = table.querySelector<HTMLElement>('tr:first-child')
  const cart = fromDecisionTree ? decisionTreeCart : contextCart
  const cartArray: CartArray = !fromDecisionTree
    ? {
        '636a6862-2123-4811-b930-8c02afd54fdf': [] as Product[],
        '2e46d418-fa10-446d-801b-d305495d10d4': [] as Product[],
        'c7699ac3-8d47-4806-9494-903d42b3be75': [] as Product[],
      }
    : null
  const catalogItems = catalogs?.[0]?.contents?.items || []

  let totalPrice = 0
  let totalDiscount = 0
  let totalDiscountPrice = 0

  if (cartArray && cart.length) {
    ;(cart as Product[]).forEach?.((cartItem) => {
      catalogItems.forEach((catalogItem) => {
        if (cartItem.id === catalogItem.id) {
          catalogItem.categories.forEach((category) => {
            if (category.id === 'c7699ac3-8d47-4806-9494-903d42b3be75') {
              cartArray['c7699ac3-8d47-4806-9494-903d42b3be75'].push(cartItem)
            }
          })
        }
      })
    })
  }

  const addNewRow = (item: Product, index: number) => {
    const priceWithoutDiscount = calculateFinalPrice(item, false)
    const priceWithDiscount = calculateFinalPrice(item, true)

    totalPrice = totalPrice + priceWithoutDiscount
    totalDiscount = totalDiscount + (priceWithoutDiscount - priceWithDiscount)
    totalDiscountPrice = totalDiscountPrice + priceWithDiscount

    const insertAfterNode = table.querySelector<HTMLElement>(`tr:nth-last-child(2)`)

    let row = document.createElement('tr')
    let exhibitNumberCell = document.createElement('td')
    let serviceNameCell = document.createElement('td')
    let priceCell = document.createElement('td')
    let discountCell = document.createElement('td')
    let discountedPriceCell = document.createElement('td')

    row.setAttribute('id', `product-${index}`)

    const cellStyles = {
      width: '30%',
      border: '1px solid #000',
      fontSize: '12px',
      fontFamily: 'inherit',
    }

    exhibitNumberCell.textContent = `Exhibit ${index + 1}`
    exhibitNumberCell.style.border = cellStyles.border
    exhibitNumberCell.style.fontSize = cellStyles.fontSize
    exhibitNumberCell.style.fontFamily = cellStyles.fontFamily

    serviceNameCell.textContent = item.name
    serviceNameCell.style.width = cellStyles.width
    serviceNameCell.style.border = cellStyles.border
    serviceNameCell.style.fontSize = cellStyles.fontSize
    serviceNameCell.style.fontFamily = cellStyles.fontFamily

    priceCell.textContent = formatCurrency(priceWithoutDiscount).toLocaleString()
    priceCell.style.border = cellStyles.border
    priceCell.style.fontSize = cellStyles.fontSize
    priceCell.style.fontFamily = cellStyles.fontFamily

    discountCell.textContent = formatCurrency(priceWithoutDiscount - priceWithDiscount).toLocaleString()
    discountCell.style.border = cellStyles.border
    discountCell.style.fontSize = cellStyles.fontSize
    discountCell.style.fontFamily = cellStyles.fontFamily

    discountedPriceCell.textContent = formatCurrency(priceWithDiscount).toLocaleString()
    discountedPriceCell.style.border = cellStyles.border
    discountedPriceCell.style.fontSize = cellStyles.fontSize
    discountedPriceCell.style.fontFamily = cellStyles.fontFamily

    row.appendChild(exhibitNumberCell)
    row.appendChild(serviceNameCell)
    row.appendChild(priceCell)
    row.appendChild(discountCell)
    row.appendChild(discountedPriceCell)

    insertAfterNode && insertAfter(insertAfterNode || tableHeader, row)
  }

  if (cart.length) {
    ;(cart as Product[]).forEach((cartItem, index) => {
      addNewRow(cartItem, index)
    })
  } else if (cartArray) {
    Object.keys(cartArray).forEach((key, index) => {
      cartArray[key as CatalogIdsEnum].forEach((item) => {
        addNewRow(item, index)
      })
    })
  }

  let newHtml = documentContainer.innerHTML.toString()

  newHtml = tagReplacer(newHtml, 'total price', formatCurrency(totalPrice).toLocaleString())
  newHtml = tagReplacer(newHtml, 'total discount', formatCurrency(totalDiscount).toLocaleString())
  newHtml = tagReplacer(newHtml, 'total discount price', formatCurrency(totalDiscountPrice).toLocaleString())

  documentContainer.innerHTML = newHtml

  return documentContainer
}
