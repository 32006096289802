import { context as ctx, getProductRow, RowVariants } from '@tools/wr-catalog-base'

export const getSelectedTemplate = (context = ctx) => {
  const { templateSelected, cart, templates } = context.state

  if (templateSelected) {
    return templateSelected
  }

  const productTemplateId: any = cart.reduce((templateId, product) => {
    const templateControl: any = getProductRow(product, RowVariants.RowType, 'component:document-template')?.control
    const productTemplateId = templateControl?.templateId.split(',')?.[0]

    return templateId || productTemplateId
  }, '')

  const productTemplate = templates?.find((template) => template.id === productTemplateId)

  if (productTemplate) {
    return {
      label: productTemplate?.name,
      value: productTemplate?.id,
    }
  }

  return null
}
