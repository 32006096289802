import React, { useEffect, useMemo } from 'react'
import {
  context,
  updateContextState,
  phasesModule,
  PhaseDetailedCostType,
  PhaseItemRowType,
  PhaseRowItemType,
} from '@tools/wr-catalog-base'
import getAddonDiscount from 'utils/product/getAddonDiscount'
import { useCartCalculations } from '@hooks/useCartCalculations'
import { getAddonsFromSelections } from '@routes/AddonsView/utils'
import getDiscount from 'utils/product/getAddonDiscount'

const getCostOptions = (selectedRegion: string) => ({
  rateOverrideKey: selectedRegion,
  getDiscount: getAddonDiscount,
})

export const PhaseCalculations = ({ children }: { children: React.ReactNode }) => {
  const { selectedRegion, phasesConfig, cart, selectedAddons, addOnItems } = context.state
  const { sumCosts, calculateItemGroupCost } = phasesModule.utils

  const { totalHours } = useCartCalculations(cart, selectedAddons as PhaseRowItemType[], phasesConfig?.itemGroups)

  const totalRateCents = useMemo(
    () =>
      phasesConfig?.itemGroups.reduce(
        (memo, itemGroup) => sumCosts(memo, calculateItemGroupCost(itemGroup, getCostOptions(selectedRegion))),
        {} as PhaseDetailedCostType,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      calculateItemGroupCost,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(phasesConfig?.itemGroups),
      selectedRegion,
      sumCosts,
    ],
  )

  useEffect(() => {
    const selectedAddonsPlus = getAddonsFromSelections(cart, addOnItems, selectedAddons)

    const itemSpecs = cart.map((item) => {
      const addons = selectedAddonsPlus.filter((addon) => addon.parentCartId === item.cartId)
      const addonPrices = addons.map((addon) => {
        const detailedCost = phasesModule.utils.calculateItemRowCost({ item: addon } as unknown as PhaseItemRowType, {
          overrideKey: selectedRegion,
          getDiscount,
        })
        return detailedCost
      })

      return {
        id: item.id,
        addons,
        addonPrices,
        totalPrice: phasesModule.utils.sumCosts(...addonPrices),
      }
    })

    const detailedPhaseTotal = phasesModule.utils.sumCosts(...itemSpecs.map((sp) => sp.totalPrice))
    const normalCartTotal = cart.reduce((acc, v) => acc + (isNaN(v.price) ? 0 : v.price), 0)
    const phasesCartTotal = (totalRateCents?.discountedCost || detailedPhaseTotal.discountedCost) / 100

    const total = normalCartTotal + phasesCartTotal

    updateContextState((state) => (state.cartCalculations.totalPrice = total))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addOnItems,
    cart,
    selectedAddons,
    selectedRegion,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    totalRateCents?.discountedCost,
  ])

  useEffect(() => {
    updateContextState((state) => (state.cartCalculations.totalHours = totalHours))
  }, [totalHours])

  useEffect(() => {
    updateContextState((state) => (state.totalCartValueWithAddons = totalRateCents))
  }, [totalRateCents])

  return <>{children}</>
}
