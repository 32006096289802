import { getAddonsFromSelections } from '@routes/AddonsView/utils'
import { context, phasesModule, Addon } from '@tools/wr-catalog-base'
import { getPhaseCostOptions } from './getPhaseCostOptions'

export const getItemSpecs = () => {
  const phaseCostOptions = getPhaseCostOptions()

  const { cart, selectedAddons, addOnItems } = context.state
  const selectedAddonsPlus = getAddonsFromSelections(cart, addOnItems, selectedAddons)

  const itemSpecs = cart.map((item) => {
    const addons = selectedAddonsPlus.filter((addon) => addon.parentCartId === item.cartId)
    const addonPrices = addons.map((addon) => {
      const detailedCost = phasesModule.utils.calculateItemRowCost({ item: addon } as any, phaseCostOptions)
      return detailedCost
    })

    return {
      id: item.id,
      addons,
      addonPrices,
      totalPrice: phasesModule.utils.sumCosts(...addonPrices),
    }
  })

  return itemSpecs
}
