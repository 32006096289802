import { isRowSkipped, safeJsonParse } from '@tools/wr-catalog-base'

export const getConsultingProductPrice = ({ formValues, product, basePrice }: GetProductPriceContext) => {
  const moreOptions = product.rows.filter((row) => ['dropdown', 'radio', 'checkbox'].includes(row.rowType))
  if (!moreOptions.length) return basePrice

  let priceWithOptions = 0

  Object.keys(formValues).forEach((key) => {
    const row = product.rows.find((item) => item.id === key)

    // don't add price for skipped rows
    if (!row || isRowSkipped(formValues, row)) return

    const priceObjects: any[] = []
    const values = formValues[key]
    const valuesAsArray = [].concat(values).filter(Boolean)

    valuesAsArray.forEach((val) => {
      const note = row.notes?.find((note) => note.value === val && note.notesType === 'multiplier')
      if (!note) {
        try {
          priceObjects.push(JSON.parse(val))
        } catch (e) {}
        return
      }

      // @refactor
      // Handle multipliers
      const parsedNoteValue = safeJsonParse<{ value: { Price: number; Hours: number } | string } | null>(
        note.value,
        null,
      )
      const parsedNotePrice = safeJsonParse<{ Price: number; Hours: number } | null>(parsedNoteValue?.value, null)

      if (parsedNotePrice && parsedNoteValue) {
        parsedNoteValue.value = JSON.stringify({
          Price: parsedNotePrice.Price * Number(note.noteValue),
          Hours: parsedNotePrice.Hours * Number(note.noteValue),
        })
        priceObjects.push(parsedNoteValue)
      }
    })

    priceObjects.forEach((parsedValue) => {
      try {
        const value = safeJsonParse<{ Price: number }>(parsedValue.value, { Price: 0 })
        priceWithOptions = priceWithOptions + (value.Price || 0)
      } catch (e) {}
    })
  })

  return priceWithOptions + basePrice
}
