import List from './List'
import LoginView from './Login'
import ProposalsView from './Proposals'
import ProductDetails from './ProductDetails'
import DocumentEdit from './DocumentEdit/index'
import AddonsView from './AddonsView'
import AugmentedPhaseAllocations from './PhaseAllocations'
import GanttView from '@components/Phases/GanttView'

export default [
  {
    path: '/',
    key: 'index',
    exact: true,
    component: List,
  },
  {
    path: '/login',
    key: 'login',
    exact: true,
    component: LoginView,
  },
  {
    path: '/proposals',
    key: 'proposals',
    exact: true,
    component: ProposalsView,
  },
  {
    path: '/add-ons',
    key: 'addOns',
    exact: true,
    component: AddonsView,
  },
  {
    path: '/phases',
    key: 'phases',
    exact: true,
    component: AugmentedPhaseAllocations,
  },
  {
    path: '/gantt',
    key: 'gantt',
    exact: true,
    component: GanttView,
  },
  {
    path: '/product/:productId',
    key: 'productDetail',
    exact: true,
    component: ProductDetails,
  },
  {
    path: '/document-edit',
    key: 'document-edit',
    exact: true,
    component: DocumentEdit,
  },
]
