import { context } from '@tools/wr-catalog-base'
import { CostOptions } from '@tools/wr-catalog-base'
import getAddonDiscount from './getAddonDiscount'

export const getPhaseCostOptions = (): CostOptions => {
  const { selectedRegion } = context.state

  return {
    rateOverrideKey: selectedRegion,
    getDiscount: getAddonDiscount,
  }
}
