import React from 'react'
import { PhaseDetailedCostType, PhaseRowItemType, Product } from '@tools/wr-catalog-base'
import { formatCurrency, phasesModule, Link, updateContextState, context } from '@tools/wr-catalog-base'
import { FaRegTrashAlt } from 'react-icons/fa'
import { getProductHours } from 'utils/product'
import getProductPrice from 'utils/product/getProductPrice'
import getDiscount from 'utils/product/getAddonDiscount'
import { CartItemWrapper, CartItemInfoAndActions, CartItemHours, AddonsListWrapper } from '../style'
import { adjustTotalWhenProductRemoved } from '@store/actions'
import { renderRoleHoursHtml, rowValueToString } from '../utils'

export const CartItem = ({
  item,
  selectedAddonsPlus,
  itemSpecs,
  removeProductFromCart,
  selectedRegion,
}: {
  item: Product
  selectedAddonsPlus: PhaseRowItemType[]
  itemSpecs: ItemSpecType[]
  removeProductFromCart: typeof context.actions.removeProductFromCart
  selectedRegion: string
}) => {
  const phaseCostOptions = {
    rateOverrideKey: selectedRegion,
    getDiscount,
  }

  const { phasesConfig, cart } = context.state

  const { getRoutePath } = context

  const cartCopyRows = item.rows.filter((v) => v.options && v.options.tag && v.options.tag.includes('cartCopy'))
  const summary = rowValueToString(cartCopyRows, ': ')

  const currentItemSpec = itemSpecs.find((phaseItem) => phaseItem.id === item.id)

  const addons = currentItemSpec?.addons

  const adjustedPhaseItems = phasesConfig?.itemGroups
    .map((itemGroup) => {
      const newItemGroup = { ...itemGroup }
      newItemGroup.itemRows = itemGroup.itemRows.filter((itemRow) => itemRow.item.parentId === item.id)

      return newItemGroup
    })
    .filter((itemGroup) => itemGroup.itemRows.length > 0)

  const adjustedItemPrice = adjustedPhaseItems
    ? phasesModule.utils.calculatePhaseConfigCosts({ itemGroups: adjustedPhaseItems }, phaseCostOptions).cost
    : ({} as PhaseDetailedCostType)

  const itemFields = getProductHours(item, cart, selectedAddonsPlus, phasesConfig?.itemGroups)

  return (
    <CartItemWrapper key={item.id} data-testid={`cart-item-${item.id}`}>
      <CartItemInfoAndActions>
        <div
          onClick={() => {
            removeProductFromCart(item.id, item)
            adjustTotalWhenProductRemoved(item)
            updateContextState((state) => {
              state.templateSelected = null
            })
          }}
          role="button"
          data-testid="cart-remove-item"
          style={{
            height: 20,
          }}
        >
          <FaRegTrashAlt className="item-delete-icon" />
        </div>
        <div>
          <span className="item-name" data-testid="cart-item-name">
            {item.name}
          </span>
          {!!addons?.length && (
            <AddonsListWrapper>
              {addons.map((addon, i) => (
                <div key={i}>{addon.name}</div>
              ))}
            </AddonsListWrapper>
          )}
          <CartItemHours>
            {renderRoleHoursHtml(itemFields)}
            {itemFields.totalHours ? <p className="cart-muted">Total Hours: {itemFields.totalHours}</p> : null}
            {itemFields.totalWeeks ? <p className="cart-muted">Total Weeks: {itemFields.totalWeeks}</p> : null}
          </CartItemHours>
          {summary.map((v, i) => (
            <span key={i} className="item-description">
              {v}
            </span>
          ))}
          <Link to={getRoutePath('productDetail', { productId: item.id })}>Edit Service</Link>
        </div>
        <div>
          <span className="cart-active" data-testid="cart-item-price">
            {adjustedItemPrice.discountedCost
              ? formatCurrency(adjustedItemPrice.discountedCost / 100)
              : formatCurrency(getProductPrice(item, true, true))}
          </span>
        </div>
      </CartItemInfoAndActions>
    </CartItemWrapper>
  )
}

export interface ItemSpecType {
  id: string
  addons: PhaseRowItemType[]
  addonPrices: PhaseDetailedCostType[]
  totalPrice: PhaseDetailedCostType
}
