import React, { useState, useEffect } from 'react'
import { styled, Grid, Row } from '@tools/wr-catalog-base'

const HeroStyled = styled.div<{ animate?: boolean }>`
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiP…Igd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g) !important;
  background-image: linear-gradient(to right bottom, #18aeff 0, #00b2e2 61%) !important;

  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
  z-index: 0;
  transition: clip-path 0.5s ease;
  margin-bottom: 50px;

  ${({ animate }) =>
    animate &&
    `
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  `}
`

const Titles = styled.div`
  position: relative;
  z-index: 9999;
  left: 0;
  color: #fff;
  display: block;
`

const Title = styled.h1`
  color: #fff;
  font-weight: 300;
  font-size: 42px;
  font-weight: 400;
  line-height: 45px;
  margin-bottom: 25px;
  font-family: 'Kanit', sans-serif;
`

const Container = styled.div.attrs({
  className: 'container',
})`
  position: relative;
  height: 220px;
  padding: 70px 200px 70px 0;
  margin: 0 auto;
`

const Hero = ({ catalogTitle }: { catalogTitle: string }) => {
  const [shouldAnimate, setShouldAnimate] = useState(true)

  useEffect(() => {
    setShouldAnimate(false)

    return () => setShouldAnimate(true)
  }, [])

  return (
    <HeroStyled animate={shouldAnimate} data-testid="hero-styled">
      <Container>
        <Titles>
          <div className="container">
            <Grid>
              <Row>
                <Title>{catalogTitle}</Title>
              </Row>
            </Grid>
          </div>
        </Titles>
      </Container>
    </HeroStyled>
  )
}

export default Hero
